import { defineStore } from 'pinia';
import { AxiosError } from 'axios';

import { getActiveLoyalty, subscribeLoyalty, deactivateLoyalty } from '@/api/loyalty';
import { LOYALTY_NAME_MAP } from '@/views/Loyalty/composable/useLoyalty';
import { LoyaltyState, LoyaltyFeaturePrograms, LoyaltySubscription, PayloadSubscribeLoyalty } from './types';


export const useLoyaltyStore = defineStore('loyalty', {
  state: (): LoyaltyState => ({
    loyaltyPrograms: [],
    activeLoyalty: [],
    error: null,
    loyaltyIcons: null,
    selectedProgramToAdd: null
  }),

  actions: {
    async loadActiveLoyalty() {
      try {

        const loyalty: LoyaltySubscription[] = await getActiveLoyalty();
        this.activeLoyalty = loyalty || [];

      } catch (e) {
        this.activeLoyalty = [];
        console.error("Retrieving loyalty action failed'", e);
      }
    },
    async setLoyaltyPrograms(providers: LoyaltyFeaturePrograms[]) {
      // We use the loyalty name instead of the provider
      // because the POIs has an array of supported loyalties with the name of program
      const loyaltyProvidersMapped = providers.filter(item => LOYALTY_NAME_MAP.includes(item.name));
      this.loyaltyIcons = loyaltyProvidersMapped.map(({ name, icon }) => ({ name, icon }));

      this.loyaltyPrograms = providers;
    },
    async registerLoyalty(payload: PayloadSubscribeLoyalty) {
      try {
        await subscribeLoyalty(payload);

        // Force reload AuthData
        await this.loadActiveLoyalty();

      } catch (e) {
        console.log('register Loyalty error', e);
        throw (e as AxiosError).response;
      }
    },
    async deactivateLoyalty(loyaltyId: string) {
      try {
        await deactivateLoyalty(loyaltyId);

        // Force reload AuthData
        await this.loadActiveLoyalty();

      } catch (e) {
        console.log('deactivate Loyalty error', e);
        throw e;

      } finally {
      }
    }
  }
});