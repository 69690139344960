<template>
  <div class="w-6 h-6 flex items-center">
    <img :src="stationImg" alt="" class="w-full h-auto" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const STATIONS_WITH_LOGO = ['aral'];
const props = defineProps({
  brand: {
    type: String,
    required: true
  }
});
const stationImg = computed(() => {
  const path = '/img/stations/';
  const brand = props.brand.toLowerCase().replace(/[^A-Z0-9]+/gi, '');
  return STATIONS_WITH_LOGO.includes(brand) ? `${path}${brand}.svg` : `${path}fallback.png`;
});
</script>
