<template>
  <TopBar
    :title="$t('history.stats.title')"
    backBtnVisible
    iconColor="text-white"
    @backButtonClick="backBtnFunc"
  />
  <div class="flex items-center justify-between px-4 py-6">
    <RPButton
      v-for="button in buttons"
      :key="button.timeFrame"
      theme="custom"
      :outline="button.timeFrame !== timeFrame"
      class="'w-1/3 mx-2 py-0 truncate"
      :class="button.timeFrame !== timeFrame ? 'bg-primary-background border border-white' : 'bg-white text-primary-background'"
      @click="toggleDate(button.timeFrame)"
    >
      {{ $t(button.label) }}
    </RPButton>
  </div>
  <div v-if="isChartDataAvailable" class="w-60 min-w-[240px] h-60 min-h-[240px] mx-auto">
    <Doughnut :data="chartData" :options="options" />
  </div>
  <div v-else class="h-60">
    <LoadingSpinner />
  </div>
  <div class="flex justify-between p-4">
    <h3 class="flex h-full font-bold rp-fs-16-ln">
      {{ $t('history.stats.table.title.name') }}
    </h3>
    <h3 class="flex h-full font-bold rp-fs-16-ln text-tertiary-text">
      {{ $t('history.stats.table.title.value') }}
    </h3>
  </div>
  <hr class="block h-[1px] border-0 border-t border-solid border-tertiary-text opacity-50 my-1 mx-0 pb-2" />

  <div class="px-4">
    <template v-for="item in currentTransactionsWithSumPrice" :key="item.category">
      <div v-if="item.price > 0" class="flex justify-between mb-2">
        <div class="flex">
          <div
            class="h-3 w-3 rounded-full mr-2"
            :style="{ 'background-color': item.color }"
          >
          </div>
          <div class="font-medium">
            {{ $t(`history.details.cost.category.${getCategoryTextUtils(item.category)}`) }}
          </div>
        </div>
        
        <div class="text-tertiary-text">
          {{ currencyString(deciCentInUnit(item.price), authStore.userCurrency || "euroDeciCent") }}
        </div>
      </div>
    </template>
  </div>
  <hr class="block h-[1px] border-0 border-t border-solid border-tertiary-text opacity-50 my-1 mx-0 pb-2" />
  <div v-if="sumPrices" class="flex justify-between px-4 py-4">
    <h3 class="flex h-full font-bold rp-fs-18-ln">
      {{ $t('history.stats.table.total') }}
    </h3>
    <h3 class="flex h-full font-bold rp-fs-18-ln">
      {{ sumPrices }}
    </h3>
  </div>
</template>

<script setup lang="ts">
import RPButton from '@/components/RPButton/RPButton.vue';
import TopBar from '@/components/TopBar.vue';
import { useHistoryStore } from '@/store/history/history';
import { useAuthStore } from '@/store/auth/auth';
import { Chart as ChartJS, ArcElement } from 'chart.js';
import { computed, onMounted, reactive, ref } from 'vue';
import { Doughnut } from 'vue-chartjs';
import { useRouter } from 'vue-router';
import { currencyString } from '@/utils/utils';
import { Expense } from '@/store/history/types';
import { getCategoryTextUtils } from '@/store/history/historyUtils';
import { isEmpty } from 'lodash';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

interface Button {
  timeFrame: string;
  label: string;
}

interface ChartData {
  labels: string[];
  datasets: {
    backgroundColor: string[];
    data: number[];
  }[];
}

ChartJS.register(ArcElement);

const historyState = useHistoryStore();
const authStore = useAuthStore();
const router = useRouter();

const backBtnFunc = () => {
  historyState.cleanTransactions();
  router.push('/history');
};

// Chart configs
const chartData: ChartData = reactive({
  labels: [],
  datasets: [
    {
      backgroundColor: [],
      data: []
    }
  ]
});
const options = {
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      display: false
    },
  },
  elements: {
    arc: {
      borderWidth: 0,
    }
  },
  cutout: 70,
};

onMounted(async () => {
  historyState.cleanTransactions();
  toggleDate('yearly');
});

// Filters
const buttons: Button[] = [
  { timeFrame: 'monthly', label: 'history.stats.period.1' },
  { timeFrame: 'semiannually', label: 'history.stats.period.2' },
  { timeFrame: 'yearly', label: 'history.stats.period.3' }
];

const endDate = ref(new Date());
const timeFrame = ref('yearly');
const today = new Date();

type datesRangePeriodType = {
  yearly: Date;
  semiannually: Date;
  monthly: Date;
};

const subtractMonths = (date: Date, months: number) => {
  date.setMonth(date.getMonth() - months);
  return date;
};

const datesRangePeriod = {
  yearly: subtractMonths(new Date(), 12).toISOString(),
  semiannually: subtractMonths(new Date(), 6).toISOString(),
  monthly: subtractMonths(new Date(), 1).toISOString()
};

const toggleDate = async (timeFrameParam: string) => {
  timeFrame.value = timeFrameParam;
  const startDateByFilter = datesRangePeriod[timeFrameParam as keyof datesRangePeriodType];
  await loadTransactions(timeFrameParam, startDateByFilter);
};

// Show data logic

const CATEGORIES_MAP = ['FUEL_TOP_UP', 'PARKING', 'CAR_CARE', 'CAR_PARTS', 'CAR_WASH', 'REPAIR', 'TAX', 'INSURANCE', 'LEASING', 'MISC'];
const COLORS = {
  FUEL_TOP_UP: '#6ae6bc',
  PARKING: '#39a0ea',
  CAR_CARE: '#597e93',
  CAR_PARTS: '#bc5231',
  REPAIR: '#df9b5b',
  TAX: '#a2b874',
  INSURANCE: '#578a59',
  LEASING: '#c56ae5',
  MISC: '#d9367a',
  CAR_WASH: '#D93636'
};
const currentTransactions = computed(() => historyState.transactions[timeFrame.value as keyof datesRangePeriodType] ?? []);

// We are returning the same structure of Expense interface but with all the price summed and the color of the category
const currentTransactionsWithSumPrice = computed(() => {

  const onlyExpenses = currentTransactions.value && currentTransactions.value.map(item => item.expenses);
  return onlyExpenses.reduce((acc: Expense[], currentValue: Expense[]) => {

    acc = CATEGORIES_MAP.map(category => {
      const getCategoryPrice = currentValue.find(i => i.category === category).price;
      const getCategoryObj = currentValue.find(i => i.category === category);
      const prevCategoryPrice = !isEmpty(acc) ? acc.find(i => i.category === category).price : 0;
      const currencyPrev = !isEmpty(acc) ? acc.find(i => i.category === category).currency : null;

      return {
        ...getCategoryObj,
        price: getCategoryPrice + prevCategoryPrice,
        color: COLORS[category as keyof typeof COLORS],
        currency: currencyPrev || getCategoryObj.currency
      };
    });
    return acc;
  }, []);

});
const deciCentInUnit = (price: number) => Number(price / 1000).toFixed(2);

const sumPricesFunc = (transactions: Expense[]) => {
  let total = 0;
  transactions.forEach((transaction) => {

    total += transaction.price;
  });
  return `${currencyString(deciCentInUnit(total), authStore.userCurrency || "euroDeciCent")}`;
};

const sumPrices = ref<string | null>(null);

const loadTransactions = async (timeFrame: string, startDateISO: string) => {
  chartData.datasets[0].backgroundColor = [];
  await historyState.getTransactionsOverview(
    timeFrame,
    today.toISOString(),
    startDateISO
  );

  chartData.datasets[0].data = currentTransactionsWithSumPrice.value.map(item => item.price);

  chartData.datasets[0].backgroundColor = currentTransactionsWithSumPrice.value.map(item => item.color);

  sumPrices.value = sumPricesFunc(currentTransactionsWithSumPrice.value);
};

const isChartDataAvailable = computed(() => {
  return (
    chartData.datasets[0].data.length > 0 &&
    chartData.datasets[0].backgroundColor.length > 0
  );
});


</script>