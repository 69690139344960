import { defineStore } from 'pinia';
import { getVehicleAPI, getAllBrandsAPI, getAllModelsAPI, createVehicleAPI, updateVehicleAPI, getVehicleIdAPI, deleteVehicleAPI } from '@/api/vehicle';
import { VehicleState, CreateVehiclePayload, UpdateVehiclePayload, VehicleType } from './types';

import { useAuthStore } from '@/store/auth/auth';

export const useVehicleState = defineStore('vehicle', {
  state: (): VehicleState => ({
    vehicle: null,
    brands: null,
    models: null,
    vehicleInfo: null,
  }),

  actions: {
    async getVehicle(thingId: string) {
      try {
        const car: VehicleType = await getVehicleAPI(thingId);
        const vehicle = {
          _id: car._id,
          id: car.id,
          account: car.account,
          type: car.type,
          package: car.package,
          ymme: car.ymme,
          status: car.status,
          users: car.users,
          readOnly: car.readOnly,
          nickName: car.nickName,
        };

        this.vehicle = vehicle;

      } catch (error) {
        throw (error);
      }
    },
    async getBrands() {
      try {
        const brands = await getAllBrandsAPI();
        this.brands = brands;
      } catch (error) {
        console.log(error);
      }
    },
    async getModels(brand: string) {
      try {
        const models = await getAllModelsAPI(brand);
        this.models = models;
      } catch (error) {
        console.log(error);
      }
    },
    async getVehicleInfo(brandId: string, modelId: string, fuelTypeId: string) {
      try {
        const vehicleInfo = await getVehicleIdAPI(brandId, modelId, fuelTypeId);
        this.vehicleInfo = vehicleInfo[0];
      } catch (error) {
        console.log(error);
      }
    },
    async createVehicle(dataCreate: CreateVehiclePayload, dataUpdate: UpdateVehiclePayload) {
      const authStore = useAuthStore();
      try {
        const vehicle = await createVehicleAPI(dataCreate);

        await updateVehicleAPI(vehicle.data, dataUpdate);
        // create a premium car
        // await updateVehicleAPI(vehicle._id, dataUpdate);
        await authStore.storeUserPreferences();
      } catch (error) {
        throw error;
      }
    },
    async updateVehicle(thingId: string, dataUpdate: UpdateVehiclePayload) {
      const authStore = useAuthStore();
      try {
        const vehicle = await updateVehicleAPI(thingId, dataUpdate);
        this.vehicle = vehicle;
        await authStore.storeUserPreferences();

      } catch (error) {
        throw error;
      }
    },
    async deleteVehicle(thingId: string, userId: string) {
      const authState = useAuthStore();
      try {
        await deleteVehicleAPI(thingId, userId);

        await authState.storeUserPreferences();
      } catch (error) {
        throw error;
      }
    },
    resetVehicleStore() {
      this.vehicle = null;
      this.brands = null;
      this.models = null;
      this.vehicleInfo = null;
    },
    resetModels() {
      this.models = null;
    },
    resetVehicleInfo() {
      this.vehicleInfo = null;
    }
  }
});