<template>
  <Teleport to="#teleportBeforeFooter">
    <div v-if="toastIsVisible" 
         class="text-center text-white w-full py-2 px-6 relative"
         :class="{
          'bg-ryd-primary': props.theme === 'info',
          'bg-ryd-warning80': props.theme === 'warning',
          'bg-ryd-error': props.theme === 'error',
        }"
    >
      <button class="absolute top-0 right-0 m-0 p-0" @click.prevent="hideToast">
        <RydIcon icon="close" class="w-8 h-8 text-white" />
      </button>
      <slot></slot>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { onBeforeMount, onMounted, ref } from 'vue';
import RydIcon from '../RydIcon/RydIcon.vue';

const props = defineProps({
  text: {
    type: String,
    default: ''
  },
  theme: {
    type: String,
    default: 'info',
    validator: value => ['info', 'warning', 'error'].includes(value as string)
  },
  position: {
    type: String,
    default: 'top'
  },
  duration: {
    type: Number,
    default: 3000
  }
});

onMounted(() => {
  if (toastIsVisible.value) return;

  setTimeout(() => {

    toastIsVisible.value = false;
    emits('isHidden');

  }, props.duration);

  toastIsVisible.value = true;
});

const toastIsVisible = ref(false);

const hideToast = () => {
  toastIsVisible.value = false;
  emits('isHidden');
};
const emits = defineEmits(['isHidden']);
</script>