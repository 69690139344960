<template>
  <div class="view-block px-4">
    <div>
      <div class="my-8 flex justify-center items-center">
        <LoyaltyIcon :programName="program.programName" class="w-12" />
      </div>
      <div class="font-medium mb-6">
        <span class=" text-tertiary-text text-fs-12">{{ $t('loyalty.edit.label_name') }}</span>
        <p>{{ program.programName }}</p>
      </div>
      <div class="font-medium mb-6">
        <span class=" text-tertiary-text text-fs-12">
          {{ program.provider === 'Q8_SMILES' ? $t('loyalty.edit.label_details_email') : $t('loyalty.edit.label_details') }}</span>
        <p>{{ getLoyaltyDetails(program) }}</p>
      </div>
    </div>
    <RPButton
      theme="error"
      class="mt-2 mb-6"
      @click="openRemoveDialog()"
    >
      {{ $t('loyalty.delete') }}
    </RPButton>
    <Dialog v-if="removeDialogOpen" @close="removeDialogOpen = false">
      <h2>{{ $t('loyalty.edit.delete_dialog_title') }}</h2>
      <p>{{ $t('loyalty.edit.delete_confirm_text') }}</p>
      <RPButton class="block w-full mt-6" @click="emitRemoveLoyalty()">
        {{ $t('loyalty.edit.delete_confirm_btn_confirm') }}
      </RPButton>
      <RPButton theme="error" class="block w-full mt-2" @click="removeDialogOpen = false">
        {{ $t('loyalty.edit.delete_confirm_btn_cancel') }}
      </RPButton>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
import RPButton from '@/components/RPButton/RPButton.vue';
import Dialog from '@/components/Dialog.vue';
import LoyaltyIcon from '@/components/LoyaltyIcon.vue';
import { useLoyalty } from '@/views/Loyalty/composable/useLoyalty';
import { LoyaltySubscription } from '@/store/loyalty/types';

import { ref, PropType } from 'vue';

const { getLoyaltyDetails } = useLoyalty();

const props = defineProps({
  program: {
    type: Object as PropType<LoyaltySubscription>,
    required: true
  }
});
const emit = defineEmits(['emitRemoveLoyalty']);
const removeDialogOpen = ref(false);

const openRemoveDialog = () => {
  removeDialogOpen.value = true;
};

const emitRemoveLoyalty = async () => {
  removeDialogOpen.value = false;
  emit('emitRemoveLoyalty', props.program.id);
};

</script>
