import { defineStore } from 'pinia';

import { VouchersState, CampaignParticipation } from './types'

import { vouchersListAPI, activateCampaignAPI, addCustomVoucherAPI, getReferAFriendAPI, putActivateReferCodeAPI} from '@/api/vouchers'

export const VOUCHER_ERROR_MAP = [
  'ACTIVE_VOUCHER', 'VOUCHER_LIST'
]
export const useVouchersStore = defineStore('vouchers', {
 state: (): VouchersState => ({
  vouchers: [],
  error: null,
}),

actions: {
  async loadVouchers() {
    try {
      const vouchers:CampaignParticipation[] = await vouchersListAPI()
      this.vouchers = vouchers

    } catch(e) {
      console.log('retrieve vouchers error', e);
      this.error = 'VOUCHER_LIST'
      throw new Error();
      
    }
  },
  async activateCampaignAction(campaignId:string) {
    try {
      await activateCampaignAPI(campaignId)
      await this.loadVouchers()
    } catch (e) {
      console.log('active voucher fail', e)
      this.error = 'ACTIVE_VOUCHER'
      throw new Error();
      
    }
  },
  async addCustomVoucher(code:string) {
    try {
      await addCustomVoucherAPI(code)
      await this.loadVouchers()
    } catch (e) {
      console.log('custom voucher fail', e)
      this.error = 'CUSTOM_VOUCHER'
      throw new Error();
      
    }
  },
  async activateReferAFriendCode() {
    try {
      await putActivateReferCodeAPI()
      await this.loadVouchers()
    } catch (e) {
      console.log('active voucher fail', e)
      this.error = 'ACTIVE_VOUCHER'
      throw new Error();
      
    }
  },
}
});