<template>
  <div class="flex items-center justify-between px-4 py-6">
    <RPButton 
      theme="secondary" 
      class="justify-between w-auto min-w-[140px] m-0 px-4 py-0 bg-card-bg" 
      @click="isFilterEvent = true"
    >
      <div class="text-primary-accent">
        {{ eventSelectedFilter === 'ALL'
          ? $t('history.details.show_all')
          : $t(`history.filter.${EVENT_FILTER_TITLE_MAP[eventSelectedFilter]}`) }}
      </div>
      <RPIcon type="arrow-down" />
    </RPButton>
    <RPButton 
      v-if="authState.userPreferences.length > 0"
      theme="secondary" 
      class="justify-between w-auto min-w-[80px] m-0 px-4 py-0 bg-card-bg" 
      @click="isFilterVehicle = true"
    >
      <RPCarIcon
        :avatar="authState.selectedVehicle.avatarImageName"
        :color="authState.selectedVehicle.avatarColor"
        class="w-6 mr-2 h-fit"
      />
      <span class="pr-2">{{ authState.selectedVehicle.nickName }}</span>
      <RPIcon type="arrow-down" />
    </RPButton>
  </div>
  <ModalBottom v-if="isFilterEvent" class="text-left" @close="isFilterEvent = false">
    <p class="rp-fs-16-ln font-semibold">
      {{ $t('history.filter.title_events') }}
    </p>
    <ul class="pt-6">
      <template v-for="filter in eventFiltersMap" :key="filter.type">
        <li 
          v-if="filter.visible" 
          class="mb-6" 
          :class="{ 'text-tertiary-text font-semibold': isFilterActive(filter.type) }"
          @click="selectFilter(filter.type)"
        >
          <button>{{ $t(`history.filter.${filter.text}`) }}</button>
        </li>
      </template>
    </ul>
  </ModalBottom>
  <ModalBottom v-if="isFilterVehicle" class="text-left" :customClass="['max-h-[240px]', 'pb-3']" @close="isFilterVehicle = false">
    <p class="rp-fs-16-ln font-semibold">
      {{ $t('history.filter.title_cars') }}
    </p>
    <ul class="pt-6">
      <li 
        v-for="vehicle in vehicles" 
        :key="vehicle.thingId" 
        class="mb-6 flex items-center"
        :class="{ 'text-tertiary-text': authState.selectedVehicle.thingId === vehicle.thingId }"
        @click="selectVehicle(vehicle)"
      >
        <RPCarIcon :avatar="vehicle.avatarImageName" :color="vehicle.avatarColor" class="w-6 mr-2 h-auto" />
        <button class="h-fit">
          {{ vehicle.nickName }}
        </button>
      </li>
    </ul>
  </ModalBottom>
</template>
<script setup lang="ts">
import ModalBottom from '@/components/ModalBottom.vue';
import RPButton from '@/components/RPButton/RPButton.vue';
import RPIcon from '@/components/RPIcon/RPIcon.vue';
import RPCarIcon from '@/components/CarsSVGComponents/RPCarIcon.vue';

import { useHistoryStore } from '@/store/history/history';
import { useAuthStore } from '@/store/auth/auth';
import { ThingAvatars } from '@/api/user';
import { ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';

const emit = defineEmits(['eventFilterChange', 'carFilterChange']);
const historyState = useHistoryStore();
const authState = useAuthStore();
const route = useRoute();
const router = useRouter();

//Events filter
const isFilterEvent = ref(false);
const isFilterVehicle = ref(false);
const selectFilter = (event: string) => {
  emit('eventFilterChange', event);
  isFilterEvent.value = false;
};
const eventFiltersMap = computed(() => [
  { type: 'ALL', text: 'all', visible: true },
  { type: 'PAYMENT', text: 'payment', visible: true },
  { type: 'TRIP', text: 'trip', visible: authState.selectedVehicle.package !== 'APP_ONLY' },
  { type: 'COST', text: 'cost', visible: true },
]);
const EVENT_FILTER_TITLE_MAP = {
  ALL: 'all',
  PAYMENT: 'payment',
  TRIP: 'trip',
  COST: 'cost',
};
const eventSelectedFilter = computed(() => historyState.eventSelectedFilter);
const isFilterActive = (filter: string) => filter === eventSelectedFilter.value;

// Vehicles filter
const vehicles = computed(() => authState.userPreferences);

const selectVehicle = (vehicle: ThingAvatars) => {
  if (authState.selectedVehicle.thingId !== vehicle.thingId) {
    authState.setVehicle(vehicle.thingId);
    const carChangedEvent = new CustomEvent("webViewHistoryThingId", { detail: { thingId: vehicle.thingId } });

    window.dispatchEvent(carChangedEvent);
    if (window.webkit) {
      window.webkit.messageHandlers.webViewHistoryThingId.postMessage(vehicle.thingId);
    }

    // We replace the thingId param in the URL
    router.replace({
      path: route.path,
      query: { ...route.query, thingId: vehicle.thingId }
    });

    emit('carFilterChange', 'ALL');
  }
  isFilterVehicle.value = false;
};

</script>