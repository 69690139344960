<template>
  <button
    class="
      checkout-method-picker
      block
      border-none border-0 outline-none
      w-screen h-screen
      bg-black/50
      cursor-default
      fixed top-0 left-0
      z-50 p-0"
    @click.prevent="emits('close')"
  >
    <div
      class="bg-primary-background w-full rounded-t-lg box-border fixed bottom-0 z-10 px-4 pt-8 overflow-y-auto"
      :class="[...props.customClass]"
      @click.prevent.stop
    >
      <div class="max-w-appMaxWidth mx-auto">
        <slot></slot>
      </div>
    </div>
  </button>
</template>
<script setup lang="ts">
const props = defineProps({
  customClass: {
    type: Array,
    default: () => []
  }
});
const emits = defineEmits(['close'])

</script>