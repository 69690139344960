<template>
  <RydTopBar :title="$t('car.page.title')" backBtnVisible @backButtonClick="$router.push('/cars')" />

  <RydLoadingOverlay v-if="!componentReady || isLoading" />
  <div v-if="componentReady" class="f-roboto view-block py-6 px-4">
    <div class="flex flex-col gap-y-2">
      <RydInputText id="carName" 
                    v-model:valueModel="carModelForm.name" 
                    name="carName" :label="$t('car.data.name')" 
                    :placeholder="$t('car.data.name_placeholder')"
                    :disabled="readOnly"
      />
      <p class="underline py-6 font-bold">
        {{ $t('car.data.edit') }}
      </p>
      <RydSelect id="carBrand"
                 v-model:valueModel="carModelForm.brand"
                 name="carBrand" :label="$t('car.data.brand')"
                 :placeholder="$t('car.data.brand_placeholder')"
                 :options="!!brands ? brands : []"
                 :displayValue="carModelForm.brand || !!brands ? 'make' : false"
                 :disabled="readOnly"
                 @update="loadModels"
      />
      <RydSelect id="carModel"
                 v-model:valueModel="carModelForm.model"
                 name="carModel" :label="$t('car.data.model')"
                 :placeholder="$t('car.data.model_placeholder')"
                 :options="!!models ? (models as any) : []"
                 :displayValue="carModelForm.model || !!models ? 'model' : false"
                 :disabled="!models || readOnly"
      />
      <RydSelect id="carFuel" 
                 v-model:valueModel="carModelForm.fuel"
                 name="carFuel" :label="$t('car.data.fuel')"
                 :placeholder="$t('car.data.fuel_placeholder')"
                 :options="fuelTypes?.length > 0 ? fuelTypes : []"
                 displayValue="displayName"
                 :disabled="(!carModelForm.model && !fuelTypes) || readOnly"
                 @update="loadVehicleInfo"
      />
      <RydSelect id="carYear" 
                 v-model:valueModel="carModelForm.year"
                 name="carYear" :label="$t('car.data.year')"
                 :placeholder="$t('car.data.year_placeholder')"
                 :options="vehicleState.vehicleInfo ? yearsArray() : []"
                 :disabled="readOnly"
                 @update="vehicleIdByYear"
      />
      <p class="underline py-6 font-bold">
        {{ $t('car.data.more') }}
      </p>
      
      <RydInputText id="carPlate" 
                    v-model:valueModel="carModelForm.plate" 
                    name="carPlate" :label="$t('car.data.license')" 
                    :placeholder="$t('car.data.license_placeholder')"
                    :disabled="readOnly"
      />
      <RydInputText id="carMileage" 
                    v-model:valueModel="carModelForm.mileage" 
                    name="carMileage" :label="$t('car.data.mileage')" 
                    :placeholder="$t('car.data.mileage_placeholder')"
                    :disabled="readOnly"
      />
      <RydInputText id="carTank" 
                    v-model:valueModel="carModelForm.tankCapacity" 
                    name="carTank" :label="$t('car.data.capacity')" 
                    :placeholder="$t('car.data.capacity_placeholder')"
                    :disabled="readOnly"
      />
      <!-- Date -->
      <div class="flex flex-col">
        <label class="rp-fs-13-ln block mb-1 font-bold">{{ $t('car.data.inspection') }}</label>
        <label class="relative flex items-center mb-2" for="dateTime">
          <p class="rp-fs-15-ln block w-full
                  p-3
                  border border-ryd-gray3 rounded-s
                  focus:border-ryd-gray5 focus:outline-none focus:ring-1 focus:ring-ryd-gray5"
             :class="readOnly ? 'bg-transparent pointer-events-none' : 'bg-white'"
          >
            <span v-if="carModelForm.dateTimeUtility">
              {{ formatDate.weekday }} {{ formatDate.date }}
            </span>
            <span v-else class="text-ryd-gray4">
              {{ $t('car.data.inspection_placeholder') }}
            </span>
          </p>
          <input
            id="dateTime"
            v-model="carModelForm.dateTimeUtility" 
            :placeholder="$t('car.data.inspection_placeholder')"
            :class="{ 'pointer-events-none': readOnly }"
            :disabled="readOnly"
            class="datepicker-input
                  absolute top-0 left-0 cursor-pointer opacity-0 h-full w-full box-border"
            type="date"
            step="any"
            @change="dateChange"
          />
          <!-- <input v-model="carModelForm.nextInspectionDate" type="hidden" /> -->
        </label>
      </div>

      <RydInputText id="carVIN" 
                    v-model:valueModel="carModelForm.vin" 
                    name="carVIN" :label="$t('car.data.vin')" 
                    :placeholder="$t('car.data.vin_placeholder')"
                    :disabled="readOnly"
      />
    </div>
    <RydButton v-if="!isPreferred && !isNewCar" outline class="w-full mt-6" @click="setAsActive">
      {{ $t('car.data.button.action1') }}
    </RydButton>
    <RydButton v-else-if="isPreferred" link theme="primary" class="w-full mt-6" disabled>
      {{ $t('car.data.already_preferred') }}
    </RydButton>
    <template v-if="!readOnly"> 
      <RydButton v-if="isNewCar" class="w-full mt-6" @click="createNewCar">
        {{ $t('car.data.button.action2') }}
      </RydButton>
      <RydButton v-else class="w-full mt-6" @click="updateCar">
        {{ $t('car.data.button.action2') }}
      </RydButton>

      <RydButton v-if="canBeDeleted" :link="true" theme="error" class="w-full mt-6" @click="isDeleteModal = true">
        {{ $t('car.data.button.action3') }}
      </RydButton>
    </template>
    <RydModalBottom v-if="isDeleteModal" 
                    :customClass="['max-h-[430px]']" 
                    @close="isDeleteModal = false"
    >
      <section class="text-left pt-10 pb-6">
        <p class="rp-fs-15-ln font-bold">
          {{ $t('car.modal.confirmation.title') }}
        </p>
        <p class="rp-fs-15-ln mb-6 mt-2">
          {{ $t('car.modal.confirmation.text') }}
        </p>
        <div class="flex justify-space-between items-center">
          <RydButton outline theme="primary" class="w-full mr-10" @click="isDeleteModal = false">
            {{ $t('car.modal.confirmation.button.action1') }}
          </RydButton>
          <RydButton theme="primary" class="w-full" @click="deleteCar">
            {{ $t('car.modal.confirmation.button.action2') }}
          </RydButton>
        </div>
      </section>
    </RydModalBottom>
    <RydToast v-if="errorAPI" 
              theme="error" 
              text="Something went wrong" 
              :position="'bottom'" 
              :duration="8000" 
              @isHidden="errorAPI = false"
    >
      {{ errorAPIText }}
    </RydToast>
  </div>
</template>

<script setup lang="ts">
import RydButton from '@/components/RydButton/RydButton.vue';
import RydLoadingOverlay from '@/components/RydLoading/RydLoadingOverlay.vue';
import RydInputText from '@/components/RydInputText/RydInputText.vue';
import RydSelect from '@/components/RydSelect/RydSelect.vue';
import RydTopBar from '@/components/RydTopbar/RydTopBar.vue';
import RydToast from '@/components/RydToast/RydToast.vue';
import RydModalBottom from '@/components/RydModalBottom/RydModalBottom.vue';

import { checkAndApplyTheme, convertUTCDateToLocalDateNoTime, dateString, removeTheme } from '@/utils/utils';
import { CreateVehiclePayload, UpdateVehiclePayload, BrandsType, ModelType, FuelTypes } from '@/store/vehicle/types';

import { useAuthStore } from '@/store/auth/auth';
import { useVehicleState } from '@/store/vehicle/vehicle';
import { computed, onMounted, ref, onBeforeMount, reactive, onBeforeUnmount } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

const authState = useAuthStore();
const vehicleState = useVehicleState();

const router = useRouter();
const route = useRoute();
const { t } = useI18n();

onBeforeMount(() => {
  checkAndApplyTheme();
});
onBeforeUnmount(() => {
  removeTheme();
  vehicleState.resetVehicleStore();

});
const componentReady = ref(false);
onMounted(async () => {
  if (route.params.id === undefined) return router.push('/cars');

  // Refresh the user preferences
  try {
    if (!authState.userPreferences?.length) await authState.storeUserPreferences();
    if (route.query.thingId) authState.setVehicle(route.query.thingId as string);

  } catch (error) {
    console.log(error);
  }

  // if is not new car
  if (!isNewCar.value) {
    try {
      await vehicleState.getVehicle(route.params.id as string);
      fillForm();
    } catch (error) {
      console.log(error);
    }
  }
  if (!readOnly.value) {
    // Load brands on mounted
    try {
      await vehicleState.getBrands();

    } catch (error) {
      console.log(error);
    }
    // If model exist, load all models and set this as the selected one and set the fuel type
    if (car.value?.ymme?.model) {
      await loadAndSetModelFuel();
    }
  }
  componentReady.value = true;

});

const readOnly = computed(() => vehicleState.vehicle?.readOnly ?? false);
const isNewCar = computed(() => route.params.id === 'new');

const brands = computed(() => vehicleState.brands);
const models = computed(() => vehicleState.models);
const fuelTypes = computed(() => carModelForm.model?.fuelTypes);
const car = computed(() => vehicleState.vehicle);
const currentCar = computed(() => authState.selectedVehicle);

const isPreferred = computed(() => route.params.id === currentCar.value?.thingId);

const loadModels = async (brand: BrandsType) => {
  // Reset the year if the user change the brand
  if (car.value?.ymme?.make !== brand.make) carModelForm.year = null;

  vehicleState.resetModels();
  vehicleState.resetVehicleInfo();

  carModelForm.model = null;
  carModelForm.fuel = null;

  try {
    await vehicleState.getModels(brand.id);

  } catch (error) {
    console.log(error);
  }
};
const loadVehicleInfo = async () => {
  try {
    await vehicleState.getVehicleInfo(
      carModelForm.brand.id,
      carModelForm.model.id,
      carModelForm.fuel.fuelTypeId
    );
  } catch (error) {
    console.log(error);
  }
};


const loadAndSetModelFuel = async () => {
  // Fill the brand object
  const getBrand = brands.value.find((brand: BrandsType) => brand.make === car.value.ymme?.make);
  carModelForm.brand = getBrand;

  await loadModels(getBrand);
  // Fill the model obkect
  const getModelId = models.value.find((model: ModelType) => model.model === car.value.ymme?.model);
  carModelForm.model = getModelId;

  // Fill the fuel type object
  const getFuelTypeInfo = fuelTypes.value.find((fuelType: FuelTypes) => fuelType.fuelTypeId === car.value.ymme?.fuelTypeId);
  getFuelTypeInfo ? carModelForm.fuel = getFuelTypeInfo : null;

  // Fill the vehicle info
  await loadVehicleInfo();
  // Fill the years
  yearsArray();
  // Fill the vehicleId
  vehicleIdByYear();
};

const yearsArray = () => {
  const startingYear = vehicleState.vehicleInfo.startYear;
  const endYear = vehicleState.vehicleInfo.endYear;
  const years = [];
  for (let i = startingYear; i < endYear + 1; i++) {
    years.push(String(i));
  }

  return years;
};
const vehicleIdByYear = (): void => {
  const year = carModelForm.year;
  if (!year) return null;

  const vehicleModelYear = vehicleState.vehicleInfo.statusArray.find((status) => {
    return year >= status.startYear && year <= status.endYear;
  });
  carModelForm.vehicleId = vehicleModelYear?.vehicleId;
};
// date time picker -----------
const formatDate = computed(() => {
  const startDate = new Date(carModelForm.dateTimeUtility);
  return dateString(startDate);
});

const dateChange = (event: Event | InputEvent) => {
  carModelForm.nextInspectionDate = new Date((event.target as HTMLInputElement).value).toISOString();
};
// -------------

const carModelForm: Record<string, any> = reactive({
  name: null,
  brand: null,
  model: null,
  fuel: null,
  year: null,
  plate: null,
  mileage: null,
  tankCapacity: null,
  nextInspectionDate: null,
  dateTimeUtility: null,
  vin: null,
  vehicleId: null,
});
const fillForm = () => {
  carModelForm.name = car.value.nickName;
  carModelForm.brand = car.value.ymme?.make
    ? { make: car.value.ymme?.make }
    : null;
  carModelForm.model = car.value.ymme?.model
    ? { model: car.value.ymme?.model }
    : null;
  carModelForm.fuel = car.value.ymme?.fuelTypeDisplayName
    ? { displayName: car.value.ymme?.fuelTypeDisplayName }
    : null;
  carModelForm.year = car.value.ymme?.year;
  carModelForm.plate = car.value.ymme?.licensePlate;
  carModelForm.mileage = mileageInKM.value;
  carModelForm.tankCapacity = car.value.status?.fuelTankSizeL;
  carModelForm.nextInspectionDate = car.value.status?.examinationDate;
  carModelForm.dateTimeUtility = car.value.status?.examinationDate
    ? convertUTCDateToLocalDateNoTime(car.value.status?.examinationDate)
    : null;
  carModelForm.vin = car.value.ymme?.VIN;
  carModelForm.vehicleId = car.value.ymme?.vehicleId;
};

const mileageInKM = computed(() => {
  // happens that the carOdometerM is not present at all
  if (!car.value.status.carOdometer?.distanceM) return null;

  const fromMToKM = car.value.status.carOdometer?.distanceM / 1000;
  return Number(fromMToKM.toFixed(0));
});


const setAsActive = () => {
  if (!isPreferred.value) {
    const carChangedEvent = new CustomEvent("webViewCarNewPreferred", { detail: { thingId: car.value._id } });

    window.dispatchEvent(carChangedEvent);
    if (window.webkit) {
      window.webkit.messageHandlers.webViewCarNewPreferred.postMessage(car.value._id);
    }

    authState.setVehicle(car.value._id);
    router.push({ path: '/cars', query: { ...route.query, thingId: car.value._id } });
  }
};
const errorAPI = ref(false);
const errorAPIText = ref();
const isLoading = ref(false);
// Update
const updateCar = async () => {
  const updateCarPayload: UpdateVehiclePayload = {
    nickName: carModelForm.name ?? 'ryd car',
    odometerM: carModelForm.mileage * 1000,
    examinationDate: carModelForm.nextInspectionDate,
    fuelTankSizeL: Number(carModelForm.tankCapacity),
    ymme: {
      licensePlate: carModelForm.plate ?? null,
      year: carModelForm.year ?? null,
      vehicleId: carModelForm.vehicleId ?? null,
      VIN: carModelForm.vin ?? null,
    }

  };
  try {
    isLoading.value = true;
    await vehicleState.updateVehicle((route.params.id as string), updateCarPayload);
    const event = new CustomEvent("webViewCarUpdate");
    await window.dispatchEvent(event);

    if (window.webkit) {
      window.webkit.messageHandlers.webViewCarUpdate.postMessage('webViewCarUpdate');
    }
    router.push('/cars');
  } catch (error) {
    errorAPI.value = true;
    errorAPIText.value = t('car.error.update');
  } finally {
    isLoading.value = false;
  }
};
// for trigger the submit on soft native keyboard
window.onKeyBoardConfirm = () => {
  if (isNewCar.value) {
    createNewCar();
  } else {
    updateCar();
  }
};

// Create
const createNewCar = async () => {

  const updateCarPayload: UpdateVehiclePayload = {
    nickName: carModelForm.name ?? 'ryd car',
    odometerM: carModelForm.mileage * 1000,
    examinationDate: new Date().toISOString(),
    fuelTankSizeL: Number(carModelForm.tankCapacity),
    ymme: {
      licensePlate: carModelForm.plate ?? null,
      year: carModelForm.year ?? null,
      vehicleId: carModelForm.vehicleId ?? null,
      VIN: carModelForm.vin ?? null,
    }
  };
  const newCarPayload: CreateVehiclePayload = {
    nickName: carModelForm.name,
    fuelType: carModelForm.fuel?.fuelType,
    licensePlate: carModelForm.plate,
    accountId: authState.accountId
  };
  try {
    isLoading.value = true;
    await vehicleState.createVehicle(newCarPayload, updateCarPayload);
    const event = new CustomEvent("webViewCarCreate");

    await window.dispatchEvent(event);
    if (window.webkit) {
      window.webkit.messageHandlers.webViewCarCreate.postMessage('webViewCarCreate');
    }
    router.push('/cars');
  } catch (error) {
    errorAPI.value = true;
    errorAPIText.value = t('car.error.create');
  } finally {
    isLoading.value = false;
  }
};

// Delete
const canBeDeleted = computed(() =>
  !isPreferred.value &&
  authState.userPreferences.length > 1 &&
  !isNewCar.value);

const isDeleteModal = ref(false);
const deleteCar = async () => {
  try {
    isLoading.value = true;
    await vehicleState.deleteVehicle(route.params.id as string, authState.userId);
    const event = new CustomEvent("webViewCarDelete");

    await window.dispatchEvent(event);
    if (window.webkit) {
      window.webkit.messageHandlers.webViewCarDelete.postMessage('webViewCarDelete');
    }
    router.push('/cars');

  } catch (error) {
    errorAPI.value = true;
    errorAPIText.value = t('car.error.delete');

  } finally {
    isLoading.value = false;
  }
};
</script>
<style lang="scss" scoped>
  .datepicker-input::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
</style>
