<template>
  <svg width="10" height="55" viewBox="0 0 10 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="9" height="9" rx="4.5" :stroke="color" />
    <rect x="4.5" y="12" width="1" height="31" :fill="color" />
    <rect y="45" width="10" height="10" rx="5" :fill="color" />
  </svg>
</template>
<script setup lang="ts">
import { PropType } from 'vue';

const COLOR_MAP = {
  'YELLOW': '#F7CA18',
  'DEFAULT': '#B1ECFF'
};
const props = defineProps({
  drivingScoreColor: {
    type: String as PropType<keyof typeof COLOR_MAP>,
    default: 'DEFAULT',
  }
});
const color = COLOR_MAP[props.drivingScoreColor];
</script>