<template>
  <div class="loading-spinner p-[15px] flex justify-center h-16 w-16">
    <img class="loading-spinner__image" src="@/assets/img/loading.png" width="60" height="60" alt="" />
  </div>
</template>

<style lang="scss" scoped>
.loading-spinner {
  &__image {
    animation: rotation 0.8s infinite linear;
  }
  @keyframes rotation {
    from {
      transform: rotateZ(0deg);
    }
    to {
      transform: rotateZ(360deg);
    }
  }
}
</style>
