import { createApp } from 'vue';
import { createPinia } from 'pinia';


import App from './App.vue';
import i18n from './i18n';
import router from './router';
import './assets/styles/tailwind.css';

window.env = VITE_GIT;

const pinia = createPinia();
const app = createApp(App)
  .use(pinia)
  .use(i18n)
  .use(router);

app.mount('#app');
if (import.meta.env.VUE_APP_ENV != 'prod') {
  console.debug(`Starting: ${VITE_GIT}`);
}