import axios, { AxiosRequestConfig } from 'axios';
import { v4 } from 'uuid';


import { useAuthStore } from '@/store/auth/auth';

const instance = axios.create();
// Handling of 401 Response
instance.interceptors.response.use(
  response => response,
  error => {
    return Promise.reject(error);
  }
);

// Add Auth + locale
instance.interceptors.request.use((config) => {
  const authStore = useAuthStore();

  config.headers = config.headers ?? {};

  if (authStore?.authToken) {
    config.headers['x-txn-auth-token'] = authStore.authToken;
  }

  config.headers['x-txn-locale'] = authStore.lang || 'en';
  config.headers['x-txn-app-version'] = `webview-${VITE_GIT}`;
  config.headers['x-txn-correlationid'] = v4();

  return config;
});

instance.defaults.baseURL = import.meta.env.VUE_APP_API_URL;
instance.defaults.timeout = 30 * 1000; // 30 seconds

export default instance;