<template>
  <TopBar
    :title="selectedProgramToDelete ? $t('loyalty.edit.header_title') : $t('loyalty.header_title')"
    backBtnVisible
    iconColor="text-white"
    @backButtonClick="backClick"
  />
  <LoadingOverlay v-if="!componentReady" />

  <LoyaltyEdit v-else-if="selectedProgramToDelete" :program="selectedProgramToDelete" @emitRemoveLoyalty="removeLoyaltyProgram" />

  <template v-else-if="!userAPIError && componentReady">
    <h2 class="rp-fs-20-ln font-bold w-full px-4 mb-6 mt-4">
      {{ $t('loyalty.active_title') }}
    </h2>
    <div class="view-block w-full px-4">
      <p v-if="!activeLoyalty.length">
        {{ $t('loyalty.no_active') }}
      </p>
      <div v-else>
        <RPCard
          v-for="item in activeLoyalty"
          :key="item.provider"
          small
          hasClick
          class="items-center flex mb-4"
          @click="openEditProgram(item)"
        >
          <div class="flex items-center font-medium w-full">
            <LoyaltyIcon :programName="item.programName" class="w-8" />
            <div class="w-full text-left truncate">
              <strong>{{ item.programName }}</strong>
              <p
                class="m-0 mt-[2px] truncate"
              >
                {{ getLoyaltyDetails(item) }}
              </p>
            </div>
          </div>
        </RPCard>
      </div>
      <RPButton
        data-cy="buttonAddPayment"
        :disabled="false"
        class="mb-6"
        @click="goToAddLoyalty()"
      >
        {{ $t('loyalty.add_cta') }}
      </RPButton>
    </div>
  </template>
  <div v-if="userAPIError">
    {{ $t('errors.generic.text') }}
  </div>
</template>

<script setup lang="ts">
import RPButton from '@/components/RPButton/RPButton.vue';
import RPCard from '@/components/RPCard/RPCard.vue';
import TopBar from '@/components/TopBar.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';

import LoyaltyIcon from '@/components/LoyaltyIcon.vue';
import LoyaltyEdit from './components/LoyaltyEdit.vue';

import { useLoyalty } from '@/views/Loyalty/composable/useLoyalty';

import { useAuthStore } from '@/store/auth/auth';
import { useLoyaltyStore } from '@/store/loyalty/loyalty';

import { useRouter, useRoute } from 'vue-router';
import { computed, onMounted, ref } from 'vue';
import { createOptin } from '@/api/optin';
import { LoyaltySubscription } from '@/store/loyalty/types';

const authState = useAuthStore();
const loyaltyState = useLoyaltyStore();

const router = useRouter();
const route = useRoute();
const componentReady = ref(false);
const userAPIError = ref(false);

const { activeLoyalty, getLoyaltyDetails } = useLoyalty();

onMounted(async () => {
  if (!loyaltyState.loyaltyPrograms.length) {
    try {
      await authState.getUserFeatures(route.query.account as string);
      await loyaltyState.loadActiveLoyalty();

    } catch (error) {
      console.log(error);
      userAPIError.value = true;
    }
  }
  componentReady.value = true;

});

const goToAddLoyalty = () => {
  router.push('/loyalty/add');
};

const backClick = () => {
  const urlCallbackObject = new URL('webViewEvent://loyaltyBack');
  selectedProgramToDelete.value
    ? (selectedProgramToDelete.value = null)
    : window.location.replace(urlCallbackObject.toString());
};

const openEditProgram = (program: LoyaltySubscription) => {
  selectedProgramToDelete.value = program;
};

// Deactivate Program
const selectedProgramToDelete = ref<LoyaltySubscription>(null);
const accountId = computed(() => authState.accountId);
const userId = computed(() => authState.userId);

const optinDataRemove = computed(() => ({
  name: `${selectedProgramToDelete.value.programName}_${selectedProgramToDelete.value.provider}`,
  action: 'reset',
  accountId: accountId.value,
  userId: userId.value
}));

const removeLoyaltyProgram = async (val: string) => {
  componentReady.value = false;
  try {
    await createOptin(optinDataRemove.value);
    //payments request

    await loyaltyState.deactivateLoyalty(val);
    selectedProgramToDelete.value = null;
    const eventDelete = new CustomEvent("webViewLoyaltyDelete");
    window.dispatchEvent(eventDelete);
    if (window.webkit) {
      window.webkit.messageHandlers.webViewLoyaltyDelete.postMessage('webViewLoyaltyDelete');
    }
  } catch (e) {
    console.log(e);
  } finally {
    componentReady.value = true;
  }
};

</script>