<template>
  <div class="flex flex-col relative">
    <label class="rp-fs-13-ln block mb-1 font-bold" :for="props.id">{{ props.label }}</label>
    <div
      :class="props.disabled
        ? 'pointer-events-none bg-transparent focus:outline-none'
        : 'bg-white cursor-pointer'"
      class="rp-fs-15-ln block w-full
        p-3
        border border-ryd-gray3 rounded-md 
        focus:border-ryd-gray5 focus:outline-none focus:ring-1 focus:ring-ryd-gray5"
      @click="selectClick"
    >
      <span v-if="valueModel" class="block">{{ displayValue ? valueModel[displayValue as string | number] : valueModel }}</span>
      <span v-else class="block text-ryd-gray4">{{ props.placeholder }}</span>
    </div>
    <RydIcon icon="arrow-dropdown" class=" absolute top-10 right-2" :class="[props.disabled ? '' : 'text-ryd-primary']" />
    <input :id="props.id" type="hidden" :name="props.name" :value="valueModel" />
  </div>

  <RydModalBottom v-if="selectModalOpen" 
                  :customClass="['max-h-[430px]']" 
                  @close="selectModalOpen = false"
  >
    <div class="sticky top-0 left-0 w-full pt-10">
      <p class="rp-fs-16-ln font-semibold text-left">
        {{ label }}
      </p>
      <RydDivider class="my-3" />
    </div>

    <div class="scrollbar overflow-hidden mt-8 max-h-[260px]">
      <label
        v-for="(option, index) in options" 
        :key="index" 
        class="mb-6 flex items-center"
        @click="selectedOption = option"
      >
        <!-- In case the option is an object we want to display a specific property -->
        <template v-if="displayValue && typeof option === 'object'">
          <RydRadio name="selectRadio"
                    :value="option[displayValue as string]"
                    :checked="valueModel?.[displayValue as string] === option[displayValue as string] || selectedOption === option"
                    :label="option[displayValue as string]"
          />
        </template>
        <template v-else>
          <RydRadio name="selectRadio"
                    :value="(option as string)"
                    :checked="valueModel === option || selectedOption === option"
                    :label="(option as string)"
          />
        </template>
      </label>
    </div>
    <div class="sticky bottom-0 bg-white pb-4">
      <RydDivider class="my-3" />
      <div class="flex justify-end">
        <RydButton outline theme="primary" @click="selectModalOpen = false">
          {{ $t('car.modal.choice.button.action1') }}
        </RydButton>
        <RydButton theme="primary" @click="setOption">
          {{ $t('car.modal.choice.button.action2') }}
        </RydButton>
      </div>
    </div>
  </RydModalBottom>
</template>

<script setup lang="ts">
import RydIcon from '../RydIcon/RydIcon.vue';
import RydModalBottom from '@/components/RydModalBottom/RydModalBottom.vue';
import RydDivider from '../RydDivider.vue';

import { ref, PropType } from 'vue';
import RydButton from '../RydButton/RydButton.vue';
import RydRadio from '../RydRadio/RydRadio.vue';

const props = defineProps({
  id: {
    type: String,
    default: () => '',
  },
  name: {
    type: String,
    default: () => '',
  },
  label: {
    type: [String, Number],
    default: () => '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  options: {
    type: Array as PropType<RydSelectOption[] | string[] | number[]>,
    default: () => [],
  },
  displayValue: {
    type: [String, Boolean, Number],
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  modalScrollHeight: {
    type: String,
    default: () => 'h-full',
  },
});

type RydSelectOption = Record<PropertyKey, string>;

const valueModel = defineModel('valueModel', { type: [Object, String, Number] });
const emits = defineEmits(['update']);
const selectModalOpen = ref(false);

const selectedOption = ref(null);

const setOption = () => {
  valueModel.value = selectedOption.value;

  emits('update', selectedOption.value);
  selectModalOpen.value = false;
};

const selectClick = () => {
  selectModalOpen.value = true;
};


</script>
<style lang="scss" scoped>
  .scrollbar {
    scrollbar-color: #265399 #265399;
    scrollbar-width: thin;
    overflow-y: scroll;

    scroll-snap-type: y mandatory;

    &label {
      scroll-snap-align: start;
    }
  }
</style>