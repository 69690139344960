<template>
  <component :is="vehicleIcon" class="w-6 mr-2 h-fit" :svgColor="vehicleColor" />
</template>
<script setup lang="ts">
import limo1 from './limo1.vue';
import { getVehicleColor } from '@/store/history/historyUtils';

import { computed, defineAsyncComponent } from 'vue';

const props = defineProps({
  color: {
    type: Number,
    default: -1
  },
  avatar: {
    type: String,
    default: 'limo1'
  }
});
const vehicleColor = computed((): string => props.color ? getVehicleColor(props.color) : '#FFFFFF');
const vehicleIcon = computed(() => {
  if (props.avatar !== null) {
    return defineAsyncComponent({
      loader: () =>
        import(`../CarsSVGComponents/${props.avatar}.vue`),
      errorComponent: limo1
    });
  }
  return defineAsyncComponent(() =>
    import('../CarsSVGComponents/limo1.vue')
  );

});
</script>