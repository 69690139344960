<template>
  <img 
    v-if="LOYALTY_NAME_MAP.includes(props.programName)" 
    :src="getLoyaltyIcon(props.programName).icon" 
    class="mr-2" 
  />
  <RPIcon v-else type="fallbacks/loyalty-fallback" noFill class="mr-2" />
</template>

<script setup lang="ts">
import RPIcon from '@/components/RPIcon/RPIcon.vue';
import { LOYALTY_NAME_MAP, getLoyaltyIcon } from '@/views/Loyalty/composable/useLoyalty';

const props = defineProps({
  programName: {
    type: String,
    required: true
  }
});
</script>