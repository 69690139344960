<template>
  <TopBar 
    :title="isManualProgramSelected ? selectedProgramToAdd.name : $t('loyalty.header_title')"
    iconColor="text-white"
    backBtnVisible
    @backButtonClick="backClick"
  />
  <LoadingOverlay v-if="!componentReady" />
  <template v-else-if="!userAPIError && componentReady">
    <LoyaltyManualAdd 
      v-if="isManualProgramSelected"
      :program="selectedProgramToAdd"
      :errorAPI="errorAPI"
      @loyaltyFields="registerProgram"
      @resetErrors="errorAPI = null"
    />
    <div v-else class="w-full px-4">
      <h3 class="text-left rp-fs-20-ln my-4 font-bold">
        {{ $t('loyalty.add_title') }}
      </h3>
      <div class="flex flex-col space-y-2 font-medium">
        <template v-for="(program, index) in loyaltyProgramsCanSubscribe" :key="index">
          <RPCard
            small
            hasClick
            data-cy="addCreditCard"
            class="justify-center flex"
            @click="selectProgram(program)"
          >
            <div class="flex items-center font-medium w-full">
              <LoyaltyIcon :programName="program.name" class="w-8" />
              {{ program.name }}
            </div>
          </RPCard>
        </template>
      </div>
      <span v-if="error" class="mt-5 block text-error">{{ $t('errors.generic.text') }}</span>
    </div>
  </template>
  <div v-if="userAPIError">
    {{ $t('errors.generic.text') }}
  </div>
</template>
<script setup lang="ts">
import RPCard from '@/components/RPCard/RPCard.vue';
import TopBar from '@/components/TopBar.vue';
import LoyaltyManualAdd from './components/LoyaltyManualAdd.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import LoyaltyIcon from '@/components/LoyaltyIcon.vue';

import { LoyaltyFeaturePrograms } from '@/store/loyalty/types';
import { useLoyalty } from '@/views/Loyalty/composable/useLoyalty';

import { createOptin } from '@/api/optin';
import { useRoute, useRouter } from 'vue-router';
import { computed, ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

import { AxiosResponse } from 'axios';
import { useAuthStore } from '@/store/auth/auth';
import { useLoyaltyStore } from '@/store/loyalty/loyalty';

const router = useRouter();
const route = useRoute();
const loyaltyState = useLoyaltyStore();
const authState = useAuthStore();
const { t } = useI18n();

const { loyaltyProgramsCanSubscribe } = useLoyalty();

const componentReady = ref(false);
const selectedProgramToAdd = ref(null);
const isManualProgramSelected = ref(false);
const userAPIError = ref(false);

onMounted(async () => {
  // Check if we Refreshed the page
  if (!loyaltyState.loyaltyPrograms.length) {
    try {
      userAPIError.value = false;

      await authState.getUserFeatures(route.query.account as string);
      await loyaltyState.loadActiveLoyalty();
    } catch (error) {
      console.log(error);
      userAPIError.value = true;
      componentReady.value = true;
      return;
    }
  }

  // Check if user is coming from checkout
  if (route.query.programId) {
    const program = loyaltyProgramsCanSubscribe.value.find(item => item._id === route.query.programId);
    selectProgram(program);
  }
  componentReady.value = true;
});

const backClick = () => {
  if (isManualProgramSelected.value && route.query.programId) {
    const urlCallbackObject = new URL('webViewEvent://loyaltyAddBack');
    window.location.replace(urlCallbackObject.toString());
    return;
  }

  isManualProgramSelected.value ? (isManualProgramSelected.value = false) : router.push('/loyalty');
};

const selectProgram = (program: LoyaltyFeaturePrograms) => {
  selectedProgramToAdd.value = program;
  loyaltyState.selectedProgramToAdd = program;
  // Enable when we got some external login page for loyalty program
  // program.signup_page
  // ? window.location.href = program.signup_page
  // : isManualProgramSelected.value = true;

  isManualProgramSelected.value = true;
};



// Register a loyalty
const accountId = computed(() => authState.accountId);
const userId = computed(() => authState.userId);

const optinData = computed(() => ({
  name: `${selectedProgramToAdd.value.name}_${selectedProgramToAdd.value.provider}`,
  action: 'set',
  accountId: accountId.value,
  userId: userId.value
}));

const error = ref(false);
const errorAPI = ref('');

const registerProgram = async (card: string, email: string) => {
  componentReady.value = false;
  try {
    await createOptin(optinData.value);

    const loyaltyAPIPayload = {
      loyaltyProgramId: selectedProgramToAdd.value._id,
      cardNumber: card,
      ...(selectedProgramToAdd.value.provider === 'Q8_SMILES' && {
        email: email,
        subscriptionMethod: 'CUSTOMER_LINKING'
      })
    };
    await loyaltyState.registerLoyalty(loyaltyAPIPayload);

    // If coming from checkout, dispatch event
    const event = new CustomEvent("webViewLoyaltyAdd");
    const eventSuccess = new CustomEvent("webViewLoyaltyAddSuccess");

    if (route.query.programId) {
      if (window.webkit) {
        window.webkit.messageHandlers.webViewLoyaltyAdd.postMessage('webViewLoyaltyAdd');
      }
      await window.dispatchEvent(event);

    } else {
      if (window.webkit) {
        window.webkit.messageHandlers.webViewLoyaltyAddSuccess.postMessage('webViewLoyaltyAddSuccess');
      }
      await window.dispatchEvent(eventSuccess);
      router.push('/loyalty');
    }

  } catch (e) {
    console.log('error register loyalty', e);
    // isManualProgramSelected.value = false;
    error.value = true;
    if ((e as AxiosResponse).data.message.includes('not belong')) {
      errorAPI.value = t('loyalty.q8smiles.form.errors.not_enrolled');
    }
    setTimeout(() => {
      errorAPI.value = null;

    }, 5000);
  } finally {
    componentReady.value = true;
  }
};
</script>