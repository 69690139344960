import { createI18n } from 'vue-i18n';
import en from './lang/en-US.json';

export const supportedLanguages = {
  de: 'Deutsch',
  en: 'English',
  nl: 'Nederlands',
  fr: 'Français',
  it: 'Italiano',
  es: 'Español',
  pt: 'Português',
  da: 'Dansk',
  pl: 'Polskie'
};

export const stringMapper: object = {
  de: 'de-DE',
  en: 'en-US',
  nl: 'nl-NL',
  fr: 'fr-FR',
  it: 'it-IT',
  es: 'es-ES',
  pt: 'pt-PT',
  da: 'da-DK',
  pl: 'pl-PL'
};

const i18n = createI18n({
  allowComposition: true,
  locale: 'en',
  fallbackLocale: 'en',
  messages: { en },
  silentTranslationWarn: import.meta.env.VUE_APP_ENV != 'dev'
});

export async function setLanguage(lang: string) {
  //Check if the lang is supported otherwise fallback en
  const chooseLang = Object.keys(supportedLanguages).includes(lang) ? lang : 'en'

  const messages = await import(`./lang/${stringMapper[chooseLang as keyof typeof stringMapper]}.json`);
  i18n.global.setLocaleMessage(chooseLang, messages.default);
  i18n.global.locale = chooseLang as keyof typeof stringMapper;
}

export default i18n;
