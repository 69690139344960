<template>
  <component
    :is="componentType"
    class="rp-button"
    v-bind="{ ...linkAttr, ...isDisabled }"
    :class="[
      `rp-button--${theme}`,

      {
        'outline': outline,
        'card': card,
        'link': link,
      },
    ]"
    @click="onClick"
  >
    <slot />
  </component>
</template>

<script>
export const BUTTON_THEMES = ['primary', 'secondary', 'error', 'custom'];

const LINK_TYPES = {
  INTERNAL: 'internal',
  EXTERNAL: 'external',
};

const attrToLinkTypeMap = {
  [LINK_TYPES.INTERNAL]: { component: 'RouterLink', attr: 'to' },
  [LINK_TYPES.EXTERNAL]: { component: 'a', attr: 'href' },
};

export default {
  props: {
    theme: {
      type: String,
      default: 'primary',
      validator: (value) => BUTTON_THEMES.includes(value),
    },
    card: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  computed: {
    linkType() {
      return this.href?.[0] === '/' ? LINK_TYPES.INTERNAL : LINK_TYPES.EXTERNAL;
    },

    componentType() {
      const componentType = this.href
        ? attrToLinkTypeMap[this.linkType].component
        : 'button';
      return componentType;
    },

    isDisabled() {
      return this.disabled ? { disabled: true } : {};
    },

    linkAttr() {
      const attrType = attrToLinkTypeMap[this.linkType].attr;
      return {
        [attrType]: this.href,
      };
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>
<style lang="scss">
.rp-button {
  @apply flex items-center justify-center
         text-center text-fs-btn font-medium leading-4
         h-btn-h
         max-w-btn-max-w w-full
         py-sm px-xl mx-auto
         rounded-full border-transparent outline-none
         transition-all duration-500 ease-out
         cursor-pointer
         appearance-none
         relative
         overflow-hidden;
  -webkit-tap-highlight-color: transparent;

  &.card {
    @apply rounded-b-xl rounded-t-none w-full h-11 leading-normal;
  }

  &:after {
    content: '';
    @apply block absolute opacity-0
           top-0 left-0
           h-full w-full;
    background: rgba(0, 0, 0, 0.2);
    border-radius: inherit;
    transition: all 0.8s;
  }

  &:active:after {
    opacity: 1;
    transition: 0s;
  }
  &:hover,
  &:focus,
  &:active {
    @apply outline-none;
  }

  &:disabled,
  &[disabled],
  &[disabled='disabled'] {
    @apply opacity-50 cursor-not-allowed pointer-events-none;
  }

  &--primary {
    @apply text-primary-btn-text  bg-primary-btn-bg;

    &.outline {
      @apply border-2 border-solid border-primary-btn-bg
             bg-transparent
             text-primary-btn-bg;
    }
    &.link {
      @apply border-none bg-transparent text-primary-btn-bg;
    }
  }
  &--secondary {
    @apply text-secondary-btn-text bg-secondary-btn-bg;

    &.outline {
      @apply border-3 border-solid border-secondary-btn-bg
             bg-transparent
             text-secondary-btn-bg;
    }
    &.link {
      @apply border-none bg-transparent text-secondary-btn-bg;
    }
    &.card {
      @apply text-primary-btn-bg border-0 border-t border-solid border-card-outline;
    }
  }

  &--error {
    @apply text-secondary-btn-text bg-error;

    &.outline {
      @apply border-3 border-solid border-error
             bg-transparent
             text-error;
    }
    &.link {
      @apply border-none bg-transparent text-error;
    }
  }
}
.link {
  @apply p-0 h-auto min-w-fit w-auto rounded-none;
}
</style>
