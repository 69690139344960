import axios from './index';

type UserAPI = {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  taxInfo: {
    taxId: string;
    country: string;
  };
};

interface UserEnrichedAPI extends UserAPI {
  gender: string;
  phoneNumber: string;
  pointsEarned: number;
  localisation: Localisation;
  accounts: Account[];
  address: Address;
}
type Account = {
  id: string;
  role: string;
};
type Address = {
  city: string;
  country: string;
  countryCode: string;
  houseNumber: string;
  street: string;
  street2: string;
  zip: string;
};
type Localisation = {
  country: string;
  currency: string;
  language: string;
};

export type ThingAvatars = {
  thingId: string;
  avatarColor?: number;
  avatarImageName?: string;
  nickName?: string;
  package?: string;
  ymme?: {
    licensePlate: string | null;
    VIN: string | null;
    vehicleId: number;
    year: string;
  };
};
export interface UserFeature {
  status: string;
  feature: string;
}
// Docs: https://tt4.thinxcloud-dev.de/doc/#api-Users
export async function getUserIdAPI(): Promise<UserAPI[]> {
  const { data } = await axios.get(`/users`);
  return data;
}
export async function getUser(userId: string): Promise<UserEnrichedAPI> {
  const { data } = await axios.get(`/users/${userId}`);
  return data;
}
export async function getUserPreferences(): Promise<ThingAvatars[]> {
  const { data } = await axios.get(`/v4/users/preferences`);
  return data.thingAvatars;
}
export async function exportEvents(thingId: string): Promise<void> {
  await axios.get(`/events/request-data-dump?thingId=${thingId}`);
}

export async function getFeaturesAPI(accountId: string): Promise<UserFeature[]> {
  const response = await axios.get(`/accounts/${accountId}/features`);
  return response.data;
}