import axios from './index';
import { CreateVehiclePayload, UpdateVehiclePayload } from '@/store/vehicle/types';
// https://tt4.thinxcloud-dev.de/doc/#api-Things_Management-getThing
// Do not rely on the APIs documentation 😑, scout into box backend-tanktaler4-api repo
// https://bitbucket.org/thinxnet/backend-tanktaler4-api/src/master/

export async function getVehicleAPI(thingId: string) {
  const { data } = await axios.get(`/things/${thingId}`);
  return data;
}
export async function getAllBrandsAPI() {
  const { data } = await axios.get(`/vehicles/makes`);
  return data.data;
}
export async function getAllModelsAPI(brand: string) {
  const { data } = await axios.get(`/vehicles/makes/${brand}/models`);
  return data.data;
}
export async function getVehicleIdAPI(brandId: string, modelId: string, fuelTypeId: string) {
  const { data } = await axios.get(`/vehicles/makes/${brandId}/models/${modelId}/fuelTypes/${fuelTypeId}`);
  return data.data;
}

// In order to update the car, we need to send the vehicleId and the year!!
export async function updateVehicleAPI(thingId: string, data: UpdateVehiclePayload) {
  const { data: updatedData } = await axios.patch(`/v3/things/${thingId}`, data);
  return updatedData;
}

// export async function createVehicleAPI(data) {
//   const { data: createdData } = await axios.post(`/things`, data);
//   return createdData;
// }
export async function createVehicleAPI(data: CreateVehiclePayload) {
  const { data: createdData } = await axios.post(`/things/lite`, data);
  return createdData;
}
export async function deleteVehicleAPI(thingId: string, userId: string) {
  const { data } = await axios.delete(`/things/${thingId}/users/${userId}`);
  return data;
}
