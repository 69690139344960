<template>
  <div class="w-full view-block" :class="{ 'pt-6': !fromProfile }">
    <div class="px-4">
      <div class="py-4 w-full flex items-center justify-between">
        <div>
          <p class="font-bold rp-fs-16-ln flex">
            <RydIcon
              icon="car-wash"
              class="h-4 w-6 mr-2 rp-fs-16-ln text-white"
            />
            {{ event.information?.carWash?.poi.brand }}
          </p>
          <div class="rp-fs-12-ln">
            {{ getFullAddress }}
          </div>
        </div>
        <RPButton
          theme="custom"
          class="mr-0 ml-auto justify-end p-0 rounded-none w-fit overflow-visible"
          @click="goToNavigation"
        >
          <RydIcon icon="history/navIcon" noFill />
        </RPButton>
      </div>
      <Divider class="w-full mt-2" />
      <!-- description -->
      <p class="font-bold rp-fs-16-ln flex"></p>
      <RPButton 
        theme="custom" 
        class="flex items-center justify-between px-0 rounded-none" 
        @click="isWashDetailsVisible = !isWashDetailsVisible"
      >
        <span class="font-bold rp-fs-16-ln">{{ event.information?.carWash?.marketingName }} 
        </span> 
        <RydIcon icon="accordion-arrow" noFill :class="{ 'rotate-180': isWashDetailsVisible }" class="transition-all" />
      </RPButton>
      <div v-if="isWashDetailsVisible" class="px-4 text-left w-full">
        <ul class="font-normal rp-fs-10-ln">
          <li 
            v-for="(item, indexItem) in event.information.carWash?.descriptionLines" 
            :key="indexItem" 
            class="list-disc text-left"
          >
            {{ item }}
          </li>
        </ul>
      </div>
      <Divider class="w-full mt-2" />

      <p class="flex justify-between w-full font-medium rp-fs-18-ln pt-4">
        <span class="text-tertiary-text">{{ $t('history.wash.amount') }}</span>
        <span>{{ amount }}</span>
      </p>
    </div>
  </div> 
  <div class="text-left w-screen  mt-4">
    <div class="max-w-appMaxWidth px-4 mx-auto">
      <RydIcon icon="history/wash-info" noFill class="mr-2" />
      {{ $t('history.wash.instructions') }}
      <Divider class="w-full mt-2" />
      <div class="flex items-center py-4">
        <RydIcon icon="history/wash-list" noFill />
        <ul class="pl-4">
          <li v-for="(item, index) in Object.values($tm('history.wash.steps'))" :key="index" class="rp-fs-12-ln">
            {{ $rt(item) }}
          </li>
        </ul>
      </div>
    </div>

    <div class="text-left w-full bg-primary-darker px-4 py-6">
      <div class="font-bold rp-fs-16-ln text-left pb-2 flex items-center justify-between max-w-appMaxWidth mx-auto">
        <span>
          {{ $t('history.wash.code') }}
        </span>
        <span class="text-tertiary-text font-normal text-right rp-fs-12-ln">
          {{ isCodeUsed ? $t('history.wash.used') : $t('topup.success.wash.expires') }} 
          {{ isCodeUsed
            ? dateNoTime(event.information.carWash.redemptionCodes[0].updatedAt)
            : dateNoTime(event.information.carWash.redemptionCodes[0].expirationDate) 
          }}
        </span>
      </div>
      <div 
        data-cy="wash-code" 
        class="h-11
              border border-card-outline rounded-md 
              text-2xl tracking-[16px] font-bold 
              flex items-center justify-center
              max-w-appMaxWidth mx-auto" 
        :class="{ 'line-through bg-primary-darker': isCodeUsed, 'bg-primary-background': !isCodeUsed }"
      >
        {{ event.information.carWash.redemptionCodes[0].code }}
      </div>
      <div v-if="markCodeError" class="text-error rp-fs-12-ln text-left pt-2 max-w-appMaxWidth mx-auto">
        {{ $t('history.wash.error_mark_code') }}
      </div>
    </div>
    <RPButton v-if="isCodeUsed" theme="custom" class="bg-primary-light text-white rounded-none w-screen h-12">
      {{ $t('history.wash.btn_used') }}
    </RPButton>
    <RPButton v-else theme="custom" class="bg-primary-btn-bg text-primary-btn-text rounded-none w-full h-12" @click="markCode">
      <LoadingSpinner v-if="isLoadingMarkCode" class="brightness-50 h-10 w-10" /> <span v-else>{{ $t('history.wash.btn_mark') }}</span>
    </RPButton>
  </div>
</template>

<script setup lang="ts">
import RydIcon from '@/components/RydIcon/RydIcon.vue';
import RPButton from '@/components/RPButton/RPButton.vue';
import Divider from '@/components/Divider.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

import { getFormattedPriceWithCurrencySymbol, dateNoTime } from '@/utils/utils';
import { updateWashCode } from '@/api/events';
import { HistoryCostDetail } from '@/store/history/types';

import { useHistoryStore } from '@/store/history/history';

import { useRoute, useRouter } from 'vue-router';
import { computed, PropType, ref, } from 'vue';
import { useI18n } from 'vue-i18n';


const historyState = useHistoryStore();

const { t } = useI18n();
const route = useRoute();

const props = defineProps({
  event: {
    type: Object as PropType<HistoryCostDetail>,
    required: true
  },
  fromProfile: {
    type: Boolean,
    default: false
  }
});

const isWashDetailsVisible = ref(false);


// Mark Code
const isCodeUsed = computed(() => props.event.information.carWash.redemptionCodes[0].isRedeemed);
const isLoadingMarkCode = ref(false);
const markCodeError = ref(false);
const markCode = async () => {
  isLoadingMarkCode.value = true;
  try {
    await updateWashCode(props.event.information.carWash.redemptionCodes[0].uuid);
    await historyState.getEventById(route.params.id as string);

    // Reload the list of events because we need to display "used on" instead of "expires"
    if (props.fromProfile) {
      await historyState.getHistoryEventsWash(1, true);
      historyState.washEventsPage = 1;

    } else {
      await historyState.getHistoryEvents(1, true);
      historyState.eventsPage = 1;

    }
    isLoadingMarkCode.value = false;

  } catch (error) {
    console.log(error);
    isLoadingMarkCode.value = false;
    markCodeError.value = true;
    setTimeout(() => {
      markCodeError.value = false;

    }, 5000);

  }
};

const getFullAddress = `${props.event.information?.carWash.poi.address.street} ${props.event.information?.carWash.poi.address.houseNumber}, 
  ${props.event.information?.carWash.poi.address.zip} ${props.event.information?.carWash.poi.address.city}`;

const goToNavigation = () => {
  if /* if we're on iOS, open in Apple Maps */
    ((navigator.platform.indexOf("iPhone") != -1) ||
    (navigator.platform.indexOf("iPod") != -1) ||
    (navigator.platform.indexOf("iPad") != -1))
    window.open(`http://maps.apple.com/?daddr=${decodeURI(getFullAddress)}`);

  else /* else use Google */
    window.open(`http://maps.google.com/maps?daddr=${decodeURI(getFullAddress)}`, '_blank');
};
const amount = computed(() => getFormattedPriceWithCurrencySymbol(
  props.event.information.totalPrice.amount,
  props.event.information.totalPrice.currency,
  2
));
</script>