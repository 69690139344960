<template>
  <svg width="87" height="64" viewBox="0 0 87 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M62.3799 0.990003C70.7899 1.78 74.9999 17.38 76.5799 18.56C78.4199 13.85 85.7399 14.35 86.0799 19.15C86.2799 21.86 78.8899 21.04 77.9699 21.28C78.3899 25.16 83.2899 27.72 85.2499 31.16C86.3599 33.12 85.9299 35.92 85.4499 41.03V60.45C85.4499 62.1 84.0899 63.46 82.4399 63.46H75.2199C74.2199 63.46 73.4099 62.65 73.4099 61.65V59.12H66.3599C66.3599 57.96 65.4099 57.02 64.2499 57.02H21.8799C20.7199 57.02 19.7799 57.96 19.7799 59.12H12.7199V61.65C12.7199 62.65 11.9099 63.46 10.9199 63.46H3.68988C2.03988 63.46 0.689878 62.1 0.689878 60.45V41.03C0.199878 35.92 -0.230122 33.12 0.889878 31.16C2.83988 27.72 7.73988 25.16 8.16988 21.28C7.24988 21.04 -0.150122 21.86 0.0498785 19.15C0.389878 14.35 7.71988 13.85 9.54988 18.56C11.1299 17.38 15.3499 1.78 23.7499 0.990003C27.8899 0.590003 35.2499 0.620003 43.0699 0.550003C50.8799 0.620003 58.2499 0.590003 62.3799 0.990003Z" :fill="props.svgColor" />
    <path d="M62.3798 0.990003C70.7898 1.78 74.9998 17.38 76.5798 18.56C78.4198 13.85 85.7398 14.35 86.0798 19.15C86.2798 21.86 78.8898 21.04 77.9698 21.28C78.3898 25.16 83.2898 27.72 85.2498 31.16C86.3598 33.12 85.9298 35.92 85.4498 41.03V60.45C85.4498 62.1 84.0898 63.46 82.4398 63.46H75.2198C74.2198 63.46 73.4098 62.65 73.4098 61.65V59.12H66.3598C66.3598 57.96 65.4098 57.02 64.2498 57.02H43.0698V0.550003C50.8798 0.620003 58.2498 0.590003 62.3798 0.990003Z" fill="white" fill-opacity="0.1" />
    <path d="M43.0698 19.12H74.3698C71.3698 22.77 63.7198 30.7 55.3598 30.7H43.0698V19.12Z" fill="white" fill-opacity="0.1" />
    <path d="M63 38.23L79.01 37.03C79.85 36.96 80.67 36.33 80.54 35.5C80.29 34.05 79.42 29.22 77.1 29.71L64.52 32.37C63.54 32.58 62.53 36.18 62.14 37.05C61.92 37.56 62.44 38.27 63 38.23Z" fill="#02141D" />
    <path d="M23.13 38.23L7.12001 37.03C6.29001 36.96 5.46001 36.33 5.60001 35.5C5.84001 34.05 6.71001 29.22 9.03001 29.71L21.61 32.37C22.6 32.58 23.6 36.18 23.99 37.05C24.22 37.56 23.69 38.27 23.13 38.23Z" fill="#02141D" />
    <path d="M43.07 19.12H74.37C73.66 18 73.04 16.48 72.64 15.6C71.62 13.37 70.56 11.14 69.25 9.05C67.89 6.86 65.79 4.1 63.23 3.3C62.89 3.19 62.54 3.12 62.18 3.08C59.52 2.83 56.75 2.8 54.07 2.75C51.11 2.71 46.03 2.69 43.07 2.67C40.1 2.69 35.03 2.71 32.06 2.75C29.38 2.8 26.61 2.83 23.95 3.08C23.59 3.12 23.24 3.19 22.9 3.3C20.34 4.1 18.24 6.86 16.88 9.05C15.57 11.14 14.51 13.37 13.49 15.6C13.09 16.48 12.47 18 11.76 19.12H43.07Z" fill="#02141D" />
    <path d="M22.98 40.33C24.18 40.42 25.24 39.72 25.78 38.74L26.41 40.56C27.34 43.19 37.54 43.79 43.07 44.42C46.68 44.06 58.65 43.59 59.72 40.56L60.36 38.74C60.89 39.72 61.96 40.42 63.15 40.33L77.06 39.28C78.93 39.15 80.59 37.67 80.55 35.77C80.49 36.46 79.75 36.97 79.01 37.03L63 38.23C62.44 38.27 61.92 37.56 62.14 37.05C62.53 36.18 63.54 32.58 64.52 32.37C61.95 32.91 60.77 32.81 58.16 32.81C59 32.81 59.86 33.51 59.69 34.33C58.54 39.94 57.62 41.26 51.54 41.7L46.19 42.08C46.15 42.08 46.11 42.08 46.08 42.08H40.06C40.02 42.08 39.98 42.08 39.94 42.08L34.59 41.7C28.51 41.26 27.59 39.94 26.44 34.33V34.29L26.43 34.26V34.22L26.42 34.18V34.14V34.1V34.07L26.43 34.03V33.99L26.44 33.96V33.92L26.45 33.89L26.46 33.85L26.47 33.82L26.48 33.78L26.49 33.75L26.51 33.71L26.52 33.68L26.54 33.65L26.56 33.61L26.57 33.58L26.59 33.55L26.61 33.52L26.63 33.49L26.65 33.46L26.68 33.43L26.7 33.4L26.72 33.37L26.75 33.34L26.78 33.31L26.8 33.29L26.83 33.26L26.86 33.24L26.89 33.21L26.92 33.19L26.95 33.16L26.98 33.14L27.01 33.12L27.04 33.09L27.07 33.07L27.11 33.05L27.14 33.03L27.17 33.01L27.21 32.99L27.24 32.98L27.28 32.96L27.32 32.94L27.35 32.93L27.39 32.92L27.43 32.9L27.46 32.89L27.5 32.88L27.54 32.87L27.58 32.86L27.62 32.85L27.66 32.84L27.69 32.83H27.73L27.77 32.82L27.81 32.81H27.85H27.89H27.93H27.97C25.36 32.81 24.19 32.91 21.61 32.37C22.6 32.58 23.6 36.18 23.99 37.05C24.22 37.56 23.69 38.27 23.13 38.23L7.11996 37.03C6.37996 36.97 5.64996 36.46 5.58996 35.77C5.53996 37.67 7.20996 39.15 9.07996 39.28L22.98 40.33ZM80.1 33.44C80.26 33.93 80.37 34.53 80.5 35.31V35.32C80.42 34.86 80.29 34.18 80.1 33.44ZM6.02996 33.44C5.86996 33.93 5.75996 34.53 5.62996 35.31V35.32C5.70996 34.86 5.83996 34.18 6.02996 33.44Z" fill="black" fill-opacity="0.3" />
    <path d="M0.689941 59.12H19.7799C19.7799 57.96 20.7199 57.02 21.8799 57.02H43.0699V46.39L0.689941 41.03V59.12Z" fill="black" fill-opacity="0.3" />
    <path d="M85.4498 41.03V59.12H66.3598C66.3598 57.96 65.4098 57.02 64.2498 57.02H43.0698V46.39L85.4498 41.03Z" fill="white" fill-opacity="0.1" />
    <path d="M46.1899 55.58L74.1499 54.38C74.9899 54.34 75.8199 53.68 75.6799 52.85C75.4299 51.4 73.7599 46.59 71.3999 46.8L46.1899 48.99C45.3999 49.06 45.1699 49.85 45.1699 50.41V54.56C45.1699 55.12 45.6299 55.6 46.1899 55.58Z" fill="#1A1918" />
    <path d="M39.9401 55.58L11.9801 54.38C11.1401 54.34 10.3101 53.68 10.4501 52.85C10.7001 51.4 12.3701 46.59 14.7301 46.8L39.9401 48.99C40.7301 49.06 40.9601 49.85 40.9601 50.41V54.56C40.9601 55.12 40.5001 55.6 39.9401 55.58Z" fill="#1A1918" />
    <path d="M73.4102 59.12V61.65C73.4102 62.65 74.2202 63.46 75.2202 63.46H82.4402C84.0902 63.46 85.4501 62.1 85.4501 60.45V48.59C82.6401 48.59 86.1501 59.12 79.1301 59.12H73.4102Z" fill="#1A1918" />
    <path d="M12.7199 59.12V61.65C12.7199 62.65 11.9099 63.46 10.9199 63.46H3.68994C2.03994 63.46 0.689941 62.1 0.689941 60.45V48.59C3.48994 48.59 -0.0200586 59.12 7.00994 59.12H12.7199Z" fill="#1A1918" />
    <path d="M19.78 59.12H66.36C66.36 57.96 65.41 57.02 64.25 57.02H21.88C20.72 57.02 19.78 57.96 19.78 59.12Z" fill="#02141D" />
    <path d="M46.19 32.81H58.16C59 32.81 59.86 33.51 59.69 34.33C58.54 39.94 57.62 41.26 51.54 41.7L46.19 42.08C46.15 42.08 46.11 42.08 46.08 42.08H40.06C40.02 42.08 39.98 42.08 39.94 42.08L34.59 41.7C28.51 41.26 27.59 39.94 26.44 34.33C26.28 33.51 27.13 32.81 27.97 32.81H46.19Z" fill="#1A1918" />
    <path d="M69.5401 32.39C69.5301 32.49 69.52 32.6 69.52 32.71C69.52 34.72 71.16 36.36 73.18 36.36C75.2 36.36 76.84 34.72 76.84 32.71C76.84 32.07 76.67 31.47 76.38 30.94L74.06 31.43C74.46 31.71 74.73 32.18 74.73 32.71C74.73 33.56 74.03 34.26 73.18 34.26C72.32 34.26 71.63 33.56 71.63 32.71C71.63 32.41 71.7101 32.13 71.8601 31.9L69.5401 32.39ZM16.59 32.39C16.6 32.49 16.61 32.6 16.61 32.71C16.61 34.72 14.97 36.36 12.95 36.36C10.93 36.36 9.30005 34.72 9.30005 32.71C9.30005 32.07 9.46005 31.47 9.75005 30.94L12.07 31.43C11.67 31.71 11.4 32.18 11.4 32.71C11.4 33.56 12.1 34.26 12.95 34.26C13.81 34.26 14.5 33.56 14.5 32.71C14.5 32.41 14.42 32.13 14.27 31.9L16.59 32.39Z" fill="white" />
    <path d="M50.1098 4.82001C51.4198 4.83001 52.7298 4.84001 54.0398 4.86001C56.6898 4.90001 59.3498 4.93001 61.9798 5.18001C62.1898 5.20001 62.3998 5.24001 62.5998 5.31001C64.5698 5.93001 66.4198 8.49001 67.4698 10.16C68.7198 12.17 69.7398 14.33 70.7198 16.48C70.8098 16.66 70.8898 16.83 70.9598 17.01H43.0698L50.1098 4.82001Z" fill="#E4E5E3" />
    <path d="M68.98 48.06C68.82 48.44 68.73 48.85 68.73 49.28C68.73 50.97 70.11 52.35 71.8 52.35C72.72 52.35 73.55 51.94 74.12 51.29C73.54 49.78 72.59 48.01 71.65 47.85C71.6 47.84 71.55 47.84 71.5 47.85L68.98 48.06Z" fill="#CBCCCB" />
    <path d="M17.15 48.06C17.31 48.44 17.4 48.85 17.4 49.28C17.4 50.97 16.03 52.35 14.33 52.35C13.41 52.35 12.58 51.94 12.02 51.29C12.59 49.78 13.54 48.01 14.48 47.85C14.53 47.84 14.58 47.84 14.64 47.85L17.15 48.06Z" fill="#CBCCCB" />
  </svg>
</template>
<script setup>
const props = defineProps({
  svgColor: {
    type: String,
    default: '#FFFFFF'
  }
});
</script>