import { computed, ref } from 'vue';
import { useLoyaltyStore } from '@/store/loyalty/loyalty';

import { LoyaltyFeaturePrograms, LoyaltySubscription } from '@/store/loyalty/types';

const LOYALTY_TYPE_MAP = {
  REPSOL: 'manual',
  NOOP: 'manual',
  Q8_SMILES: 'manual'
};
export const LOYALTY_NAME_MAP = [
  'Repsol Move',
  'Q8 Smiles'
]
export function useLoyalty() {
  const loyaltyState = useLoyaltyStore();

  const activeLoyalty = computed<LoyaltySubscription[]>(() => loyaltyState.activeLoyalty);
  const loyaltyPrograms = computed<LoyaltyFeaturePrograms[]>(() => loyaltyState.loyaltyPrograms);

  const loyaltyProgramsCanSubscribe = computed(() =>
    loyaltyPrograms.value.filter((item: LoyaltyFeaturePrograms) => !item.blockNewSubscriptions)
  );

  const getLoyaltyDetails = (program: LoyaltySubscription) => {
    if (LOYALTY_TYPE_MAP[(program.provider as keyof typeof LOYALTY_TYPE_MAP)] === 'manual')
    return program.provider === 'Q8_SMILES' ? program.details.email : program.details.cardNumber ;
  };

  const loyaltyIcons = computed(() => loyaltyState.loyaltyIcons)
  return {
    activeLoyalty,
    loyaltyPrograms,
    loyaltyProgramsCanSubscribe,
    getLoyaltyDetails,
    loyaltyIcons,
    LOYALTY_NAME_MAP,
  };
}
export const getLoyaltyIcon = (programName:string) => {
  const loyaltyIcons = useLoyalty().loyaltyIcons
  return  loyaltyIcons.value.find(item => item.name === programName)
}