<template>
  <TopBar 
    backBtnVisible 
    :title="$t('profile.wash.title_details')" 
    data-cy="back-to-profile" 
    iconColor="text-white"
    @backButtonClick="router.push('/profile/wash')"
  />
  <div v-if="componentReady" class="pt-6 flex flex-col items-center grow">
    <!-- Enable when there will be the wash data -->
    <WashDetails
      v-if="event" 
      fromProfile
      :event="(event as HistoryCostDetail)"
    />
 
    <div v-else class="px-4">
      {{ $t('history.event_not_found') }}
    </div>
  </div>
  <LoadingOverlay v-else />
</template>

<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import WashDetails from '@/views//History/components/WashDetails.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';

import { HistoryCostDetail } from '@/store/history/types';

import { useHistoryStore } from '@/store/history/history';
import { useRoute, useRouter } from 'vue-router';
import { computed, onMounted, ref } from 'vue';


const historyState = useHistoryStore();
const route = useRoute();
const router = useRouter();

const componentReady = ref(false);
onMounted(async () => {
  try {
    await historyState.getEventById(route.params.id as string);
  } catch (error) {
    console.log(error);
  } finally {
    componentReady.value = true;
  }
});

const event = computed(() => historyState.event);

</script>