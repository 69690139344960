<template>
  <TopBar 
    :title="$t('history.merge.title')"
    rightBtnVisible
    rightBtnIcon="close"
    iconColor="text-white"
    @rightButtonClick="backButtonRedirect()"
  />
  <LoadingSpinner v-if="!componentIsReady" />
  <div v-else-if="allEvents.length" class="w-full view-block pt-4 px-4">
    <RPCard
      v-for="event in allEvents"
      :id="event.id"
      :key="event.id"
      class="mb-4 border-secondary-btn-bg"
    >
      <TripSummary
        :event="(event as HistoryTripEventDetail)"
        isMerge
        :isSelected="itemShouldBeInArray.includes(event.id)" 
        class="py-2 px-4" 
        @toggleIsSelected="clickedItem(event.id)"
      />
    </RPCard>
    <RPButton class="w-full sticky bottom-6" :disabled="itemShouldBeInArray.length <= 1" @click="mergeTrips">
      {{ $t('history.merge.button.primary') }}
    </RPButton>
  </div>
</template>
<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import TripSummary from '@/components/TripSummary/TripSummary.vue';
import RPButton from '@/components/RPButton/RPButton.vue';
import RPCard from '@/components/RPCard/RPCard.vue';

import { computed, onBeforeUnmount, onMounted, onUpdated, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useHistoryStore } from '@/store/history/history';
import { useAuthStore } from '@/store/auth/auth';
import { HistoryTripEventDetail, HistoryTripEvent } from '@/store/history/types';
import { mergeEvents } from '@/api/events';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

const route = useRoute();
const router = useRouter();
const historyState = useHistoryStore();

const componentIsReady = ref(false);
const currentEvent = computed(() => historyState.event);
const currentEventId = computed(() => currentEvent.value?.id);


onMounted(async () => {
  try {
    await historyState.getEventById(route.params.id as string);
    await historyState.getEventsOlder(currentEvent.value.sampleTime);
    await historyState.getEventsNewer(currentEvent.value.sampleTime);

  } catch (error) {
    console.log('event fails', error);
  } finally {
    componentIsReady.value = true;
  }

});

/// We cannot retrieve the currentEventElement in the mounted
// so we need to listen to the component update assign the element to a ref and on the watch scroll to the element 🤷‍♀️
const currentEventElement = ref(null);
onUpdated(() => {
  currentEventElement.value = document.getElementById(currentEventId.value);
});
watch(currentEventElement, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    currentEventElement.value.scrollIntoView({ block: 'center' });
  }
});

const backButtonRedirect = () => {
  router.push(`/history/${currentEvent.value.id}`);
};


// show events
const eventsBefore = computed(() => historyState.mergeEvents.eventsBefore);
const eventsAfter = computed(() => historyState.mergeEvents.eventsAfter);

// TODO:the events to show should NOT contain the one that have 'isResultOfMerge' attribute
const allEvents = computed(() => {
  const eventsAfterWithoutCurrent = eventsBefore.value.length > 1
    ? eventsAfter.value.filter(item => item.id !== currentEvent.value.id)
    : [];

  const eventsBeforeWithoutCurrent = eventsBefore.value.length > 1
    ? eventsBefore.value.filter(item => item.id !== currentEvent.value.id)
    : [];

  return [...eventsBeforeWithoutCurrent, currentEvent.value, ...eventsAfterWithoutCurrent];
});

onBeforeUnmount(() => historyState.resetMergeEvents());

// select events
const allEventsId = computed(() => allEvents.value && allEvents.value.map(event => event?.id));

const startSlice = ref();
const endSlice = ref();
const itemShouldBeInArray = ref();

watch(allEventsId, () => {
  startSlice.value = allEventsId.value.indexOf(currentEventId.value);
  endSlice.value = allEventsId.value.indexOf(currentEventId.value);
  itemShouldBeInArray.value = [currentEventId.value];
});

const clickedItem = (val: string) => {

  const indexInitEvent = allEventsId.value.indexOf(currentEventId.value);
  const indexClicked = allEventsId.value.indexOf(val);

  startSlice.value = indexClicked <= indexInitEvent
    ? indexClicked
    : startSlice.value;

  endSlice.value = indexClicked >= indexInitEvent
    ? indexClicked
    : endSlice.value;

  itemShouldBeInArray.value = allEventsId.value.slice(startSlice.value, endSlice.value + 1);
};

// merge trips
const authStore = useAuthStore();
const mergeTrips = async () => {
  try {
    const newTrip = await mergeEvents(itemShouldBeInArray.value, authStore.selectedVehicle.thingId);
    // once obtained the new merged trip id we have to redirect to the details of this one
    // and we have to re-trigger the all history events
    historyState.eventsPage = 1;
    historyState.getHistoryEvents(1, true);

    router.push(`/history/${newTrip.id}`);
  } catch (error) {
    //TODO: ask designer what to show in this case
    console.log('failed to merge trips', error);

  }
};
</script>