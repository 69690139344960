<template>
  <div class="px-4">
    <div class="border-b border-card-outline py-4 w-full flex items-center justify-between">
      <div>
        <p class="font-bold rp-fs-16-ln flex">
          <StationImg :brand="event.information?.poi.brand" class="mr-2" />{{ event.information?.poi.brand }}
        </p>
        <div class="rp-fs-12-ln">
          {{ getFullAddress }}
        </div>
      </div>
      <a class="navButton" :href="`https://maps.google.de/maps?daddr=${decodeURI(getFullAddress)}`">
        <RPIcon type="navIcon" noFill />
      </a>
    </div>
    <div class="border-b border-card-outline py-4 w-full space-y-2">
      <p class="flex justify-between w-full font-medium">
        <span>{{ $t('history.details.order_id') }}</span>
        <span class="font-normal text-tertiary-text text-right">{{ event.information.orderId }}</span>
      </p>
      <p class="flex justify-between w-full font-medium">
        <span>{{ $t('history.details.time_stamp') }}</span>
        <span class="font-normal text-right">{{ formatDate.weekday }} {{ formatDate.date }} - {{ formatDate.time }}</span>
      </p>
      <p class="flex justify-between w-full font-medium">
        <span>{{ $t('history.details.fuel_type') }}</span>
        <span class="font-normal text-right">{{ event.information.items[0].details.name }}</span>
      </p>
      <p class="flex justify-between w-full font-medium">
        <span>{{ $t('history.details.pump') }}</span>
        <span class="font-normal">{{ event.information.items[0].details.pumpNumber }}</span>
      </p>
    </div>
    <div v-if="authStore.selectedVehicle.package !== 'APP_ONLY'" class="border-b border-card-outline py-4 w-full">
      <p class="flex justify-between w-full font-medium">
        <span>{{ $t('history.details.mileage') }}</span>
        <span class="font-normal">{{ formatMileage }}</span>
      </p>
    </div>
    <div v-if="vouchers.length && event.information.totalBeforeDiscounts" class="border-b border-card-outline py-4 w-full rp-fs-18-ln">
      <p class="flex justify-between w-full font-medium">
        <span>{{ $t('history.details.amount') }}</span>
        <span class="font-normal">
          {{ currencyString(event.information.totalBeforeDiscounts.amount, event.information.totalBeforeDiscounts.currency) }}
        </span>
      </p>
    </div>
    <div class="border-b border-card-outline py-4 w-full space-y-2">
      <p class="flex justify-between w-full font-medium">
        <span>{{ $t('history.details.tank') }}</span>
        <span class="font-normal">{{ event.information.items[0].details.quantity }} l</span>
      </p>
      <p class="flex justify-between w-full font-medium">
        <span>{{ $t('history.details.unit_price') }}</span><span class="font-normal text-right">{{ pricePerUnit }}</span>
      </p>
      <template v-if="vouchers.length">
        <p v-for="voucher in vouchers" :key="voucher.id" class="flex justify-between w-full font-medium">
          <span>{{ voucher.details.name }}</span>
          <span class="font-normal text-tertiary-base text-right">{{ currencyString((voucher.totalPrice / 1000), voucher.currency) }}</span>
        </p>
      </template>
      <template v-if="event.information.loyaltyItems">
        <div v-for="loyalty in event.information.loyaltyItems" :key="loyalty.id" class="flex justify-between w-full font-medium">
          <span>{{ loyalty.programName }}</span>
          <div>
            <span v-if="loyalty.totalAmount" class="font-normal">
              ({{ loyalty.totalAmount }})
            </span>
            <span class="font-normal text-tertiary-base">+{{ loyalty.amount }}</span>
          </div>
        </div>
      </template>
    </div>
    <div v-if="event.information.pspInformation" class="border-b border-card-outline py-4 w-full">
      <p class="flex justify-between w-full font-medium rp-fs-16-ln">
        <span>{{ $t(getPaymentMethod) }}</span>
        <span class="font-normal text-right">{{ event.information.pspInformation.maskedProviderIdentifier }}</span>
      </p>
    </div>
    <p class="flex justify-between w-full font-medium rp-fs-18-ln pt-4">
      <span>{{ $t('history.details.total') }}</span>
      <span>{{ currencyString(event.information.price.amount, event.information.price.currency) }}</span>
    </p>
  </div>
</template>
<script setup lang="ts">
import StationImg from '@/components/StationImg.vue';

import { HistoryPaymentEventDetail } from '@/store/history/types';
import { useAuthStore } from '@/store/auth/auth';
import { computed, PropType, } from 'vue';
import { currencyString, dateString, currencySymbol, localeNumber } from '@/utils/utils';
import RPIcon from '@/components/RPIcon/RPIcon.vue';

const props = defineProps({
  event: {
    type: Object as PropType<HistoryPaymentEventDetail>,
    required: true
  }
});

const formatDate = computed(() => {
  const startDate = new Date(props.event.sampleTime);
  return dateString(startDate);
});

const PAYMENT_METHODS_MAP = {
  DIRECT_CREDIT: 'history.payments.credit_card',
  GOOGLE_PAY: 'history.payments.google',
  APPLE_PAY: 'history.payments.apple',
  MBWAY: 'history.payments.mbway',
  PAYPAL: 'history.payments.paypal',
  AMAZON_PAY: 'history.payments.amazon',
  POSTPAY_BY_PARTNER: 'POSTPAY_BY_PARTNER',
  POSTPAY_BY_USER: 'POSTPAY_BY_USER'
};
const getPaymentMethod = computed(() => PAYMENT_METHODS_MAP[props.event.information.pspInformation.providerMethod]);

const authStore = useAuthStore();

const pricePerUnit = computed(() => {
  const price = props.event.information.items[0].details.unitPrice;
  const currency = props.event.information.items[0].currency;
  const unit = props.event.information.items[0].details.unit;

  const currencyPerUnitSymbol = currencySymbol(currency);
  const parseNumber = localeNumber(price / 1000);
  return `${parseNumber} ${currencyPerUnitSymbol}/${unit}`;
});
const formatMileage = new Intl.NumberFormat(authStore.lang || undefined, {
  style: 'unit',
  unit: 'kilometer',
  unitDisplay: 'short',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
}).format(props.event.information.carOdometerM / 1000);

const getFullAddress = `${props.event.information?.poi.address.street} ${props.event.information?.poi.address.houseNumber}, 
  ${props.event.information?.poi.address.zip} ${props.event.information?.poi.address.city}`;

const vouchers = computed(() => {
  return props.event.information?.items.filter(item => item.totalPrice < 0);
});
</script>