<template>
  <div v-if="!isLoaded" class="animated-background"></div>
  <img 
    :src="`${props.imageUrl}?size=500w120h`" 
    alt="voucher image" 
    class="object-cover w-full h-full max-h-[120px]" 
    :class="{ 'saturate-0': !props.isActivated, 'hidden': !isLoaded }"
    width="100%"
    height="120"
    @error="imageLoadError"
    @load="loading"
  />
</template>
<script setup lang="ts">
import voucherFallbackImg from '@/assets/img/voucher-fallback.jpg';
import { ref } from 'vue';

const props = defineProps({
  imageUrl:{
    type: String,
    required: true
  },
  isActivated:{
    type: Boolean,
    default: false
  }
})
const loading= () =>{
  isLoaded.value = true
}
const isLoaded = ref(false)
const imageLoadError = (e: Event) => {
  const target = e.target as HTMLImageElement;
  target.src = voucherFallbackImg;
};
</script>

<style lang="scss">

@keyframes placeHolderShimmer{
    0%{
        background-position: -300px 0
    }
    100%{
        background-position: 300px 0
    }
}

.animated-background {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: darkgray;
    background: linear-gradient(to right, #5074AC 10%, #265399 18%, #5074AC 33%);
    background-size: 800px 120px;
    height: 100px;
    position: relative;
}
</style>