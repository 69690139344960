<template>
  <div 
    class="absolute top-0 left-0
    bg-black opacity-80
      w-full h-full z-20
      flex flex-col items-center justify-center"
  >
    <LoadingSpinner v-if="activateIsLoading" />
    <template v-if="activateError">
      <RPIcon type="error" noFill class="w-8 h-auto" />
      <p class="font-medium pt-3">
        {{ $t('vouchers.error.title') }}
      </p>
      <p class="text-center" v-html="$t('vouchers.error.sub_title')" />
    </template>
  </div>
</template>
<script lang="ts">
import RPIcon from '@/components/RPIcon/RPIcon.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
  components: { RPIcon, LoadingSpinner },
  props: {
    activateIsLoading: {
      type: Boolean,
      required: true
    },
    activateError: {
      type: Boolean,
      required: true
    }
  },
};
</script>