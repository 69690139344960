<template>
  <component :is="iconComponent" v-if="iconComponent" class="rp-icon nofill" :class="!isCustomColor && baseIconColor" />
</template>

<script setup lang="ts">
import { defineAsyncComponent, computed, ref } from 'vue';

const props = defineProps({
  icon: { //exact name of the svg file
    type: String,
    required: true
  },
  fallback: {
    type: String,
    default: ''
  },
  isCustomColor: {
    type: Boolean,
    default: false
  },
  customColor: {
    type: String,
    default: 'false'
  }
});
const baseIconColor = localStorage.theme === 'light' ? 'text-ryd-darkMain' : 'text-ryd-lightMain';
const fallbackComponent = computed(() => {
  return defineAsyncComponent({
    loader: () => {
      if (props.fallback) return import(`./assets/fallbacks/${props.fallback}.svg`);
    }
  });
});

const iconComponent = computed(() => {
  const icon = props.icon;
  return defineAsyncComponent({
    loader: () => {
      if (icon.includes('/')) {
        // https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#globs-only-go-one-level-deep
        const splitIconType = icon.split('/');
        return import(`./assets/${splitIconType[0]}/${splitIconType[1]}.svg`);
      } else {
        return import(`./assets/${icon}.svg`);
      }
    },
    errorComponent: props.fallback ? fallbackComponent.value : null
  });
});

</script>

<style lang="scss">
  svg.rp-icon {
    @apply inline-block;
  }
</style>
