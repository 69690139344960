<template>
  <div class="flex items-center">
    <input type="radio" :value="props.value" :name="props.name" :checked="props.checked" />
    <span class="rp-fs-13-ln pl-2">{{ props.label }}</span>
  </div>
</template>
<script setup lang="ts">
const props = defineProps({
  modelValue: {
    type: String,
  },
  label: {
    type: [String, Number],
  },
  name: {
    type: String,
  },
  value: {
    type: [String, Number],
    default: ''
  },
  checked: {
    type: Boolean,
    default: false,
  },
});
</script>

<style lang="scss" scoped>
  input[type="radio"] {
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 20px;
    height: 20px;
    border: 0.15em solid currentColor;
    border-radius: 50%;
    display: grid;
    place-content: center;
  }

  input[type="radio"]::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #265399;
  }

  input[type="radio"]:checked::before {
    transform: scale(1);
  }
</style>