
import ModalBottom from '@/components/ModalBottom.vue';

<template>
  <ModalBottom v-if="props.modalIs === 'category'" :customClass="['h-[527px]']" @close="emit('close')">
    <p class="rp-fs-16-ln font-semibold text-left">
      {{ $t('history.details.cost.filter_category_title') }}
    </p>
    <ul class="pt-6 text-left font-bold">
      <li 
        v-for="category in Object.keys(COST_EVENTS_TEXT_MAP)" 
        :key="category" 
        class="mb-6" 
        @click="emit('categoryChange', category)"
      >
        <button class="w-full text-left" :class="{ 'text-tertiary-text': selectedCategory === category }">
          <RPIcon :type="`history/${getCategoryIconUtils(category)}`" noFill class="mr-3" />
          {{ $t(`history.details.cost.category.${getCategoryTextUtils(category)}`) }}
        </button>
      </li>
    </ul>
  </ModalBottom>
  <ModalBottom v-if="props.modalIs === 'repeat'" :customClass="['h-[335px]']" @close="emit('close')">
    <p class="rp-fs-16-ln font-semibold text-left">
      {{ $t('history.details.cost.filter_repeat_title') }}
    </p>
    <ul class="pt-8 text-left font-bold">
      <li 
        v-for="value in Object.keys(RECURRENCE_TEXT_MAP)" 
        :key="value" 
        class="mb-6" 
        @click="emit('recurrenceChange', value)"
      >
        <button class="w-full text-left" :class="{ 'text-tertiary-text': selectedRecurrence === value }">
          {{ $t(`history.details.cost.repeat.${recurrenceText(value)}`) }}
        </button>
      </li>
    </ul>
  </ModalBottom>
  <ModalBottom v-if="props.modalIs === 'delete'" :customClass="['h-[240px]']" @close="emit('close')">
    <p class="rp-fs-16-ln font-semibold text-left">
      {{ $t('history.details.cost.delete.title') }}
    </p>
    <ul class="pt-6 text-left font-bold">
      <li 
        v-for="value in Object.keys(DELETE_TEXT_MAP)" 
        :key="value" 
        class="mb-2" 
        @click="emit('typeOfDelete', value)"
      >
        <RPButton :outline="value !== 'all'" :theme="value === 'all' ? 'error' : 'primary'">
          {{ $t(`history.details.cost.delete.${deleteText(value)}`) }}
        </RPButton>
      </li>
    </ul>
  </ModalBottom>
  <ModalBottom v-if="props.modalIs === 'deleteOnce'" :customClass="['h-[195px]']" @close="emit('close')">
    <p class="rp-fs-16-ln font-semibold text-left">
      {{ $t('history.details.cost.delete.title_deleteOnce') }}
    </p>
    <RPButton outline class="mt-6 mb-2" @click="emit('close')">
      {{ $t(`history.details.cost.delete.cancel`) }}
    </RPButton>
    <RPButton theme="error" @click="emit('typeOfDelete', 'onlyThis')">
      {{ $t(`history.details.cost.delete.remove`) }}
    </RPButton>
  </ModalBottom>
</template>

<script setup lang="ts">
import ModalBottom from '@/components/ModalBottom.vue';
import RPIcon from '@/components/RPIcon/RPIcon.vue';
import RPButton from '@/components/RPButton/RPButton.vue';

import {
  getCategoryIconUtils,
  getCategoryTextUtils,
  COST_EVENTS_TEXT_MAP,
  RECURRENCE_TEXT_MAP,
  DELETE_TEXT_MAP
} from '@/store/history/historyUtils';

const props = defineProps({
  modalIs: {
    type: String,
    required: true,
    validator: (val: string) => ['category', 'repeat', 'delete', 'deleteOnce'].includes(val)
  },
  selectedCategory: {
    type: String,
    required: true
  },
  selectedRecurrence: {
    type: String,
    required: true
  }
});
const emit = defineEmits(['close', 'categoryChange', 'recurrenceChange', 'typeOfDelete']);
// repeat get text
const recurrenceText = (value: string) => {
  return RECURRENCE_TEXT_MAP[value as keyof typeof RECURRENCE_TEXT_MAP];
};
const deleteText = (value: string) => {
  return DELETE_TEXT_MAP[value as keyof typeof DELETE_TEXT_MAP];
};
</script>