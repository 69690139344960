<template>
  <MapboxMap 
    ref="mapRef"
    access-token="pk.eyJ1IjoidGhpbngiLCJhIjoiY2tvOHF2ZjNsMDlvNzJwcDJ0eDF6YXA2ZiJ9.uHhop42BpxUIwN-DtRkgkA"
    mapStyle="mapbox://styles/thinx/cknfypsgy494v17nks623rdbo" 
    :center="[0, 0]"
    :zoom="14"
    style="height: 260px"
    :bounds="getBoundaries"
    :fit-bounds-options="fitBoundsOptions"
    :interactive="false"
    @mb-load="tracePath()"
  >
    <MapboxMarker
      v-if="startEvent"
      :lng-lat="[(startEvent?.location as Coordinate).longitude, (startEvent?.location as Coordinate).latitude]" 
    >
      <div class="h-4 w-4 rounded-full bg-primary-background" />
    </MapboxMarker>
    <template v-if="tripEvents.length">
      <MapboxMarker 
        v-for="(event, index) in tripEvents"
        :key="index"
        :lng-lat="[(event.location as Coordinate).longitude, (event.location as Coordinate).latitude]" 
      >
        <RPIcon :type="`history/${getPinIcon(event.reason)}`" noFill class="mb-8" />
      </MapboxMarker>
    </template>
    <MapboxMarker 
      v-if="endEvent && filter === 'all'"
      :lng-lat="[(endEvent?.location as SegmentEventLocation).current.longitude, (endEvent?.location as SegmentEventLocation).current.latitude]" 
    >
      <RPIcon :type="`history/pin-end`" noFill class="mb-8" />
    </MapboxMarker>
  </MapboxMap>
</template>
<script setup lang="ts">
import RPIcon from '@/components/RPIcon/RPIcon.vue';

import 'mapbox-gl/dist/mapbox-gl.css';
import { MapboxMap, MapboxMarker } from '@studiometa/vue-mapbox-gl';

import { TimelineEvent, SegmentEventLocation, Coordinate } from '@/api/types';
import { computed, ref, PropType, ComputedRef } from 'vue';

type TripEventPinMap = {
  [key in 'HARD_CORNER' | 'HARD_ACCELERATION' | 'HARD_BRAKING']: string;
};
const TRIP_EVENT_PIN_MAP: TripEventPinMap = {
  HARD_CORNER: 'pin-corner',
  HARD_ACCELERATION: 'pin-acc',
  HARD_BRAKING: 'pin-braking'
};
const props = defineProps({
  timeline: {
    type: Array as PropType<TimelineEvent[]>,
    required: true
  },
  filter: {
    type: String,
    required: true
  }
});
const mapRef = ref();
const mapInstance = computed(() => mapRef.value.map);

// Retrieve only lat lng form the timeline where type is SEGMENT_EVENT od 'DRIVER_EVENT'
const pathCoordinates = () => props.timeline.reduce((acc: Array<number[]>, val) => {

  if (['TRIP_EVENT', 'SEGMENT_EVENT', 'DRIVER_EVENT'].includes(val.type)) {
    const location = val.type === 'SEGMENT_EVENT'
      ? (val.location as SegmentEventLocation).current
      : val.location as Coordinate;
    const latLng = [location.longitude, location.latitude];
    acc.push(latLng);
  }

  return acc;
}, []);

const fitBoundsOptions = {
  linear: true,
  padding: 40,
};

// Get boundaries to include the whole path in the map view
const getBoundaries = computed(() => {
  const longCoo = pathCoordinates().map(item => item[0]);
  const latCoo = pathCoordinates().map(item => item[1]);
  const minLng = Math.min(...longCoo);
  const minLat = Math.min(...latCoo);
  const maxLng = Math.max(...longCoo);
  const maxLat = Math.max(...latCoo);

  return [
    [minLng, minLat],
    [maxLng, maxLat],
  ];
});

const startEvent = computed(() => props.timeline.find(event => event.reason === 'TRIP_START'));
const endEvent = computed(() => props.timeline.find(event => event.reason === 'TRIP_END'));
const driverEvents = computed(() => props.timeline.filter(event => event.type === 'DRIVER_EVENT'));

const tripEvents: ComputedRef<TimelineEvent[] | []> = computed(() =>
  driverEvents.value.length
    ? driverEvents.value.filter(event => props.filter === 'all'
      ? event.type === 'DRIVER_EVENT'
      : event.reason === props.filter)
    : []);

const getPinIcon = (reason: string) => TRIP_EVENT_PIN_MAP[reason as keyof typeof TRIP_EVENT_PIN_MAP];
const tracePath = () => {
  mapInstance.value.addSource('route', {
    'type': 'geojson',
    'data': {
      'type': 'Feature',
      'properties': {},
      'geometry': {
        'type': 'LineString',
        'coordinates': [...pathCoordinates()]
      }
    }
  });
  mapInstance.value.addLayer({
    'id': 'route',
    'type': 'line',
    'source': 'route',
    'layout': {
      'line-join': 'round',
      'line-cap': 'round'
    },
    'paint': {
      'line-color': '#2E3283',
      'line-width': 4
    }
  });
};

</script>


