import axios from './index';

// Docs: https://tt4.thinxcloud-dev.de/doc/#api-Optin
type OptInData = {
  action: string;
  name: string;
  userId: string;
  thingId?: string;
  accountId?: string;
  optInText?: string;
}

export const createOptin = async (optinData: OptInData) => {
  return axios.post('/optins', optinData);
};
