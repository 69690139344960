import axios from './index';

export const vouchersListAPI = async() => {
  const response = await axios.get(`v4/vouchers/campaign-participations`);
  return response.data
};

export const activateCampaignAPI = async (campaignId: string) => {
  await axios({
    method: 'post',
    url: 'v4/vouchers/campaign-participation-activations',
    data: {
      campaignId
    }
  });

};
export const addCustomVoucherAPI = async (voucherCode: string) => {
  await axios({
    method: 'post',
    url: 'v4/vouchers/vouchers',
    data: {
      code: voucherCode
    }
  });

};
export const getReferAFriendAPI = async () => {
  const response = await axios.get('/v4/vouchers/referral-program-participations')
  return response.data
};

export const putActivateReferCodeAPI = async () => {
  const response = await axios.put('v4/vouchers/referral-code');
  return response.data
};