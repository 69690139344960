<template>
  <main class="text-center px-4">
    <RPIcon svg-fill="text" type="error" class="mt-11 mb-2 mx-auto" />
    <h2>{{ $t('404.title') }}</h2>
    <p v-html="$t('404.message')" />
  </main>
</template>
<script>
import RPIcon from '@/components/RPIcon/RPIcon.vue';

export default {
  components: { RPIcon },
};
</script>

