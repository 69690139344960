<template>
  <div class="text-center view-block">
    <RPIcon svg-fill="text" type="maintenance" class="mx-auto" />
    <h2 class="mt-md">{{ $t('maintenance.headline') }}</h2>
    <p class="font-medium my-2">{{ $t('maintenance.text') }}</p>

    <footer class="text-center mt-auto">
      <RPButton primary @click="reload">
        {{ $t('maintenance.btn') }}
      </RPButton>
    </footer>
  </div>
</template>

<script>
import RPButton from '@/components/RPButton/RPButton.vue';
import RPIcon from '@/components/RPIcon/RPIcon.vue';

export default {
  components: { RPButton, RPIcon },
  methods: {
    reload() {
      window.location.assign('/');
    },
  },
};
</script>
