<template>
  <header class="
    h-11 w-full
    sticky top-0 left-0 bg-primary-background
    z-20"
  >
    <div class="relative h-11 w-full">
      <RPButton
        v-if="props.backBtnVisible"
        theme="custom"
        class="p-0 min-w-min absolute left-0 w-11"
        :class="iconColor"
        data-cy="backButton"
        @click="$emit('backButtonClick')"
      >
        <RydIcon icon="arrowLeft" isCustomColor :customColor="iconColor" />
      </RPButton>
      <h3
        id="topBarTitle"
        class="flex justify-center items-center h-full font-bold rp-fs-16-ln"
        data-cy="headerTitle"
      >
        {{ props.title }}
      </h3>
      <div id="topBarIconRight" class="absolute right-0 top-0">
      </div>
      <RPButton
        v-if="props.rightBtnVisible"
        theme="custom"
        class="p-0 min-w-min absolute right-0 w-11 top-0"
        data-cy="backButton"
        @click="$emit('rightButtonClick')"
      >
        <RydIcon :icon="props.rightBtnIcon" />
      </RPButton>
    </div>
  </header>
</template>
<script setup lang="ts">
import RPButton from '@/components/RPButton/RPButton.vue';
import RydIcon from './RydIcon/RydIcon.vue';

const props = defineProps({
  backBtnVisible: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
  rightBtnVisible: {
    type: Boolean,
    default: false,
  },
  rightBtnIcon: {
    type: String,
    default: '',
  },
  iconColor: {
    type: String,
    default: 'text-ryd-darkMain',
  },
});
const emits = defineEmits(['backButtonClick', 'rightButtonClick']);
</script>
